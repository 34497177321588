import {
  banner1_cnImg,
  banner1_cnsImg,
  banner1_enImg,
  banner2_cnImg,
  banner2_cnsImg,
  banner2_enImg,
  banner3_cnImg,
  banner3_cnsImg,
  banner3_enImg,
  banner4_cnImg,
  banner4_cnsImg,
  banner4_enImg,
  banner5_cnImg,
  banner5_cnsImg,
  banner5_enImg,
  binanceImg,
  coinTradingImg,
  tokocrytoLogo2Img,
  usdtTradingImg,
} from "../images";
import { routes } from "../route";

export const platformLogo = {
  binance: binanceImg,
  tokocrypto: tokocrytoLogo2Img,
};

export const englishBanner = [
  banner1_enImg,
  banner2_enImg,
  banner3_enImg,
  banner4_enImg,
  // banner5_enImg,
];
export const chinsesBanner = [
  banner1_cnImg,
  banner2_cnImg,
  banner3_cnImg,
  banner4_cnImg,
  // banner5_cnImg,
];
export const chineseSbanner = [
  banner1_cnsImg,
  banner2_cnsImg,
  banner3_cnsImg,
  banner4_cnsImg,
  // banner5_cnsImg,
];

export const allBannerData = {
  en: englishBanner,
  indo: englishBanner,
  cn: chinsesBanner,
  cns: chineseSbanner,
};

export const robotStrategy = [
  {
    title: "usdtITS",
    value: "usdtITS",
    path: (obj) => {
      return routes.highFrequency.to({
        ...obj,
      });
    },
    desc: "usdtITSDesc",
    img: usdtTradingImg,
    iconSizeWidth: 50,
    iconSizeHeight: 50,
    key: "highFrequency",
  },
  {
    title: "coinITS",
    value: "coinITS",
    path: (obj) => {
      return routes.coinTrading.to({
        ...obj,
      });
    },
    desc: "coinITSDesc",
    iconSizeWidth: 50,
    iconSizeHeight: 45,
    img: coinTradingImg,
    key: "highFrequencyCoin",
  },
];

export const savedRobotType = [
  {
    value: "usdtITS",
  },
  {
    value: "smartITS",
  },
];

export const linkRobotTypes = {
  smartITS : 2,
  multiLink : 3
}

export const robotStrategyDataKey = {
  usdt: "highFrequency",
  coin: "highFrequencyCoin",
};

export const RECORD_DATA = [
  {
    text: "扣除余额",
    textE: "Deduct Credit",
    date: "2021-10-01",
    time: "19:30:20",
    transaction: "-0.121212",
  },
  {
    text: "扣除余额",
    textE: "Deduct Credit",
    date: "2021-10-01",
    time: "19:30:20",
    transaction: "-0.121212",
  },
  {
    text: "扣除余额",
    textE: "Deduct Credit",
    date: "2021-10-01",
    time: "19:30:20",
    transaction: "-0.121212",
  },
  {
    text: "扣除余额",
    textE: "Deduct Credit",
    date: "2021-10-01",
    time: "19:30:20",
    transaction: "-0.121212",
  },
];

export const RECORD_DATA_TOTAL = [
  {
    text: "盈利",
    textE: "Profit",
    date: "2021-10-01",
    time: "19:30:20",
    transaction: "7.806727",
  },
  {
    text: "盈利",
    textE: "Profit",
    date: "2021-10-01",
    time: "19:30:20",
    transaction: "7.806727",
  },
  {
    text: "盈利",
    textE: "Profit",
    date: "2021-10-01",
    time: "19:30:20",
    transaction: "7.806727",
  },
  {
    text: "盈利",
    textE: "Profit",
    date: "2021-10-01",
    time: "19:30:20",
    transaction: "7.806727",
  },
];

export const RECORD_DATA_TRADE = [
  {
    text: "扣除余额",
    textE: "Deduct Credit",
    side: "Close Short",
    date: "2021-10-01",
    time: "19:30:20",
    transaction: "47,565.01",
  },
  {
    text: "扣除余额",
    textE: "Deduct Credit",
    side: "Close Short",
    date: "2021-10-01",
    time: "19:30:20",
    transaction: "47,565.01",
  },
  {
    text: "扣除余额",
    textE: "Deduct Credit",
    side: "Open Short",
    date: "2021-10-01",
    time: "19:30:20",
    transaction: "47,565.01",
  },
  {
    text: "扣除余额",
    textE: "Deduct Credit",
    side: "Open Short",
    date: "2021-10-01",
    time: "19:30:20",
    transaction: "47,565.01",
  },
];

export const tradeSymbols = [
  "btcusdt@trade",
  "ethusdt@trade",
  "bnbusdt@trade",
  "bccusdt@trade",
  "neousdt@trade",
  "ltcusdt@trade",
  "qtumusdt@trade",
  "adausdt@trade",
  "xrpusdt@trade",
  "eosusdt@trade",
  "tusdusdt@trade",
  "iotausdt@trade",
  "xlmusdt@trade",
  "ontusdt@trade",
  "trxusdt@trade",
  "etcusdt@trade",
  "icxusdt@trade",
  "venusdt@trade",
  "nulsusdt@trade",
  "vetusdt@trade",
  "paxusdt@trade",
  "bchusdt@trade",
  "bsvusdt@trade",
  "usdcusdt@trade",
  "linkusdt@trade",
  "wavesusdt@trade",
  "bttusdt@trade",
  "usdsusdt@trade",
  "ongusdt@trade",
  "hotusdt@trade",
  "zilusdt@trade",
  "zrxusdt@trade",
  "fetusdt@trade",
  "batusdt@trade",
  "xmrusdt@trade",
  "zecusdt@trade",
  "iostusdt@trade",
  "celrusdt@trade",
  "dashusdt@trade",
  "nanousdt@trade",
  "omgusdt@trade",
  "thetausdt@trade",
  "enjusdt@trade",
  "mithusdt@trade",
  "maticusdt@trade",
  "atomusdt@trade",
  "tfuelusdt@trade",
  "oneusdt@trade",
  "ftmusdt@trade",
  "algousdt@trade",
  "usdsbusdt@trade",
  "gtousdt@trade",
  "erdusdt@trade",
  "dogeusdt@trade",
  "duskusdt@trade",
  "ankrusdt@trade",
  "winusdt@trade",
  "cosusdt@trade",
  "npxsusdt@trade",
  "cocosusdt@trade",
  "mtlusdt@trade",
  "tomousdt@trade",
  "perlusdt@trade",
  "dentusdt@trade",
  "mftusdt@trade",
  "keyusdt@trade",
  "stormusdt@trade",
  "dockusdt@trade",
  "wanusdt@trade",
  "funusdt@trade",
  "cvcusdt@trade",
  "chzusdt@trade",
  "bandusdt@trade",
  "busdusdt@trade",
  "beamusdt@trade",
  "xtzusdt@trade",
  "renusdt@trade",
  "rvnusdt@trade",
  "hcusdt@trade",
  "hbarusdt@trade",
  "nknusdt@trade",
  "stxusdt@trade",
  "kavausdt@trade",
  "arpausdt@trade",
  "iotxusdt@trade",
  "rlcusdt@trade",
  "mcousdt@trade",
  "ctxcusdt@trade",
  "troyusdt@trade",
  "viteusdt@trade",
  "fttusdt@trade",
  "eurusdt@trade",
  "ognusdt@trade",
  "drepusdt@trade",
  "bullusdt@trade",
  "bearusdt@trade",
  "ethbullusdt@trade",
  "ethbearusdt@trade",
  "tctusdt@trade",
  "wrxusdt@trade",
  "btsusdt@trade",
  "lskusdt@trade",
  "bntusdt@trade",
  "ltousdt@trade",
  "eosbullusdt@trade",
  "eosbearusdt@trade",
  "xrpbullusdt@trade",
  "xrpbearusdt@trade",
  "stratusdt@trade",
  "aionusdt@trade",
  "mblusdt@trade",
  "cotiusdt@trade",
  "bnbbullusdt@trade",
  "bnbbearusdt@trade",
  "stptusdt@trade",
  "wtcusdt@trade",
  "datausdt@trade",
  "xzcusdt@trade",
  "solusdt@trade",
  "ctsiusdt@trade",
  "hiveusdt@trade",
  "chrusdt@trade",
  "btcupusdt@trade",
  "btcdownusdt@trade",
  "gxsusdt@trade",
  "ardrusdt@trade",
  "lendusdt@trade",
  "mdtusdt@trade",
  "stmxusdt@trade",
  "kncusdt@trade",
  "repusdt@trade",
  "lrcusdt@trade",
  "pntusdt@trade",
  "compusdt@trade",
  "bkrwusdt@trade",
  "scusdt@trade",
  "zenusdt@trade",
  "snxusdt@trade",
  "ethupusdt@trade",
  "ethdownusdt@trade",
  "adaupusdt@trade",
  "adadownusdt@trade",
  "linkupusdt@trade",
  "linkdownusdt@trade",
  "vthousdt@trade",
  "dgbusdt@trade",
  "gbpusdt@trade",
  "sxpusdt@trade",
  "mkrusdt@trade",
  "daiusdt@trade",
  "dcrusdt@trade",
  "storjusdt@trade",
  "bnbupusdt@trade",
  "bnbdownusdt@trade",
  "xtzupusdt@trade",
  "xtzdownusdt@trade",
  "manausdt@trade",
  "audusdt@trade",
  "yfiusdt@trade",
  "balusdt@trade",
  "blzusdt@trade",
  "irisusdt@trade",
  "kmdusdt@trade",
  "jstusdt@trade",
  "srmusdt@trade",
  "antusdt@trade",
  "crvusdt@trade",
  "sandusdt@trade",
  "oceanusdt@trade",
  "nmrusdt@trade",
  "dotusdt@trade",
  "lunausdt@trade",
  "rsrusdt@trade",
  "paxgusdt@trade",
  "wnxmusdt@trade",
  "trbusdt@trade",
  "bzrxusdt@trade",
  "sushiusdt@trade",
  "yfiiusdt@trade",
  "ksmusdt@trade",
  "egldusdt@trade",
  "diausdt@trade",
  "runeusdt@trade",
  "fiousdt@trade",
  "umausdt@trade",
  "eosupusdt@trade",
  "eosdownusdt@trade",
  "trxupusdt@trade",
  "trxdownusdt@trade",
  "xrpupusdt@trade",
  "xrpdownusdt@trade",
  "dotupusdt@trade",
  "dotdownusdt@trade",
  "belusdt@trade",
  "wingusdt@trade",
  "ltcupusdt@trade",
  "ltcdownusdt@trade",
  "uniusdt@trade",
  "nbsusdt@trade",
  "oxtusdt@trade",
  "sunusdt@trade",
  "avaxusdt@trade",
  "hntusdt@trade",
  "flmusdt@trade",
  "uniupusdt@trade",
  "unidownusdt@trade",
  "ornusdt@trade",
  "utkusdt@trade",
  "xvsusdt@trade",
  "alphausdt@trade",
  "aaveusdt@trade",
  "nearusdt@trade",
  "sxpupusdt@trade",
  "sxpdownusdt@trade",
  "filusdt@trade",
  "filupusdt@trade",
  "fildownusdt@trade",
  "yfiupusdt@trade",
  "yfidownusdt@trade",
  "injusdt@trade",
  "audiousdt@trade",
  "ctkusdt@trade",
  "bchupusdt@trade",
  "bchdownusdt@trade",
  "akrousdt@trade",
  "axsusdt@trade",
  "hardusdt@trade",
  "dntusdt@trade",
  "straxusdt@trade",
  "unfiusdt@trade",
  "roseusdt@trade",
  "avausdt@trade",
  "xemusdt@trade",
  "aaveupusdt@trade",
  "aavedownusdt@trade",
  "sklusdt@trade",
  "susdusdt@trade",
  "sushiupusdt@trade",
  "sushidownusdt@trade",
  "xlmupusdt@trade",
  "xlmdownusdt@trade",
  "grtusdt@trade",
  "juvusdt@trade",
  "psgusdt@trade",
  "1inchusdt@trade",
  "reefusdt@trade",
  "ogusdt@trade",
  "atmusdt@trade",
  "asrusdt@trade",
  "celousdt@trade",
  "rifusdt@trade",
  "btcstusdt@trade",
  "truusdt@trade",
  "ckbusdt@trade",
  "twtusdt@trade",
  "firousdt@trade",
  "litusdt@trade",
  "sfpusdt@trade",
  "dodousdt@trade",
  "cakeusdt@trade",
  "acmusdt@trade",
  "badgerusdt@trade",
  "fisusdt@trade",
  "omusdt@trade",
  "pondusdt@trade",
  "degousdt@trade",
  "aliceusdt@trade",
  "linausdt@trade",
  "perpusdt@trade",
  "rampusdt@trade",
  "superusdt@trade",
  "cfxusdt@trade",
  "epsusdt@trade",
  "autousdt@trade",
  "tkousdt@trade",
  "pundixusdt@trade",
  "tlmusdt@trade",
  "1inchupusdt@trade",
  "1inchdownusdt@trade",
  "btgusdt@trade",
  "mirusdt@trade",
  "barusdt@trade",
  "forthusdt@trade",
  "bakeusdt@trade",
  "burgerusdt@trade",
  "slpusdt@trade",
  "shibusdt@trade",
  "icpusdt@trade",
  "arusdt@trade",
  "polsusdt@trade",
  "mdxusdt@trade",
  "maskusdt@trade",
  "lptusdt@trade",
  "nuusdt@trade",
  "xvgusdt@trade",
  "atausdt@trade",
  "gtcusdt@trade",
  "tornusdt@trade",
  "keepusdt@trade",
  "ernusdt@trade",
  "klayusdt@trade",
  "phausdt@trade",
  "bondusdt@trade",
  "mlnusdt@trade",
  "dexeusdt@trade",
  "c98usdt@trade",
  "clvusdt@trade",
  "qntusdt@trade",
  "flowusdt@trade",
  "tvkusdt@trade",
  "minausdt@trade",
  "rayusdt@trade",
  "farmusdt@trade",
  "alpacausdt@trade",
  "quickusdt@trade",
  "mboxusdt@trade",
  "forusdt@trade",
  "requsdt@trade",
  "ghstusdt@trade",
  "waxpusdt@trade",
  "tribeusdt@trade",
  "gnousdt@trade",
  "xecusdt@trade",
  "elfusdt@trade",
  "dydxusdt@trade",
  "polyusdt@trade",
  "idexusdt@trade",
  "vidtusdt@trade",
  "usdpusdt@trade",
  "galausdt@trade",
  "ilvusdt@trade",
  "yggusdt@trade",
  "sysusdt@trade",
  "dfusdt@trade",
  "fidausdt@trade",
  "frontusdt@trade",
  "cvpusdt@trade",
  "agldusdt@trade",
  "radusdt@trade",
  "betausdt@trade",
  "rareusdt@trade",
  "laziousdt@trade",
  "chessusdt@trade",
  "adxusdt@trade",
  "auctionusdt@trade",
  "darusdt@trade",
  "bnxusdt@trade",
  "rgtusdt@trade",
  "movrusdt@trade",
  "cityusdt@trade",
  "ensusdt@trade",
  "kp3rusdt@trade",
  "qiusdt@trade",
  "portousdt@trade",
  "powrusdt@trade",
  "vgxusdt@trade",
  "jasmyusdt@trade",
  "ampusdt@trade",
  "plausdt@trade",
  "pyrusdt@trade",
  "rndrusdt@trade",
  "alcxusdt@trade",
  "santosusdt@trade",
  "mcusdt@trade",
  "anyusdt@trade",
  "bicousdt@trade",
  "fluxusdt@trade",
  "fxsusdt@trade",
  "voxelusdt@trade",
  "highusdt@trade",
  "cvxusdt@trade",
  "peopleusdt@trade",
  "ookiusdt@trade",
  "spellusdt@trade",
  "ustusdt@trade",
  "joeusdt@trade",
  "achusdt@trade",
  "imxusdt@trade",
  "glmrusdt@trade",
  "lokausdt@trade",
  "scrtusdt@trade",
  "api3usdt@trade",
  "bttcusdt@trade",
  "acausdt@trade",
  "ancusdt@trade",
  "xnousdt@trade",
  "woousdt@trade",
  "alpineusdt@trade",
  "tusdt@trade",
  "astrusdt@trade",
  "nbtusdt@trade",
  "gmtusdt@trade",
  "kdausdt@trade",
  "apeusdt@trade",
];
