import { apiUrl, _axios_base_get_list } from "..";
import { placementPrefix } from "./index";
// const PAGESIZE = 100;

export default function getPlacements(limit, skip = 0, query = {}) {
  return _axios_base_get_list(
    `${apiUrl}/${placementPrefix}/getPlacements`,
    limit,
    skip,
    query
  );
}
