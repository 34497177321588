export const sourceKey = {
  user: "user",
  home: "home",
  reward: "reward",
  asset: "asset",
  highFrequency: "high-frequency",
  activationCode: "activation-code",
  enquiry: "enquiry",
  robotSimulate: "robot-simulate",
  email: "email",
  internalTrading: "internal-trading",
  newAndVideo: "news-video",
  admin: "admin",
  api: "api",
  pairing: "pairing",
  profile: "profile",
  faq: "faq",
};
