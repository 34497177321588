import address from "./address.png";
import addressWhiteIcon from "./addressWhiteIcon.png";
import agreementIcon from "./agreementIcon.png";
import android from "./android.png";
import apiNewIcon from "./apiNewIcon.png";
import assetNewIcon from "./assetNewIcon.png";
import bellIcon from "./bellIcon.png";
import binance from "./binance.png";
import binanceSmallIcon from "./binanceIcon.png";
import binanceWord from "./binanceWord.png";
import blueBanner2 from "./blueBanner2.png";
import blueBanner3 from "./blueBanner3.png";
import blueBanner4 from "./blueBanner4.png";
import blueBanner5 from "./blueBanner5.png";
import closeMenuIcon from "./closeMenuIcon.png";
import coins from "./coins.png";
import comingSoon from "./coming_soon.png";
import communiryNewIcon from "./communiryNewIcon.png";
import comunity from "./comunity.png";
import congra from "./congra.png";
import count_down_waiting from "./count_down_waiting.jpg";
import csWhite from "./csWhite.png";
import customer_service from "./customer_service.png";
import directReward2 from "./directReward2.png";
import document from "./document.png";
import downloadNewIcon from "./downloadNewIcon.png";
import email from "./email.png";
import exploreCommunity from "./exploreCommunity.png";
import faqNewIcon from "./faqNewIcon.png";
import forced_liquidation from "./forced_liquidation.png";
import ftxIcon from "./ftxIcon.png";
import ftxWhite from "./ftxWhite.png";
import ftxWord from "./ftxWord.png";
import giftReward from "./giftReward.png";
import Group_1705 from "./Group_1705.png";
import Group_385 from "./Group_385.png";
import homeActive from "./homeActive.png";
import homenavIcon from "./homenavIcon.png";
import icon from "./icon.png";
import incomeCap2 from "./incomeCap2.png";
import infoNewIcon from "./infoNewIcon.png";
import leaderReward2 from "./leaderReward2.png";
import level0 from "./level0.png";
import level1 from "./level1.png";
import level2 from "./level2.png";
import level3 from "./level3.png";
import level4 from "./level4.png";
import level5 from "./level5.png";
import level6 from "./level6.png";
import level7 from "./level7.png";
import Lock from "./Lock.png";
import loginBg from "./loginBg.png";
import loginLogo from "./loginLogo.png";
import logoutIcon from "./logoutIcon.png";
import menuIcon from "./menuIcon.png";
import newLogo from "./newLogo.png";
import news2 from "./news2.png";
import noData from "./noData.jpg";
import nodeTeam2 from "./nodeTeam2.png";
import notification_icon from "./notification_icon.png";
import not_found from "./not_found.png";
import package2 from "./package2.png";
import performanceActiveNav from "./performanceActiveNav.png";
import performanceNav from "./performanceNav.png";
import placementReward2 from "./placementReward2.png";
import pre_stop from "./pre_stop.png";
import profile from "./profile.png";
import profileActive from "./profileActive.png";
import profilenavIcon from "./profilenavIcon.png";
import register2 from "./register2.png";
import revenueNewIcon from "./revenueNewIcon.png";
import robot from "./robot.png";
import robotActiveNav from "./robotActiveNav.png";
import robotBanner from "./robotBanner.png";
import robotNav from "./robotNav.png";
import sandClockLoader from "./sandClockLoader.gif";
import settingWhite from "./settingWhite.png";
import simulatorNewIcon from "./simulatorNewIcon.png";
import speaker from "./speaker.png";
import strategy_setting from "./strategy_setting.png";
import summaryBack3 from "./summaryBack3.png";
import themeBackground from "./themeBackground.png";
import threeDot from "./threeDot.png";
import tokocryptologo from "./tokocryptologo.png";
import upload_image from "./upload_image.png";
import usdt from "./usdt.png";
import video2 from "./video2.png";
import vipReward from "./vipReward.png";
import walletIcon from "./walletIcon.png";
import warning from "./warning.jpg";
import website_maintenance from "./website_maintenance.jpg";
import robotNotify from "./robotNotify.png";
import globalLanguage from "./globalLanguage.png";
import notEnough from "./notEnough.png";
import tokocrytoLogo2 from "./tokocrytoLogo2.png";
import banner1_cn from "./banner1_cn.jpg";
import banner1_en from "./banner1_en.jpg";
import banner1_cns from "./banner1_cns.jpg";
import banner2_cn from "./banner2_cn.jpg";
import banner2_en from "./banner2_en.jpg";
import banner2_cns from "./banner2_cns.jpg";
import banner3_cn from "./banner3_cn.jpg";
import banner3_en from "./banner3_en.jpg";
import banner3_cns from "./banner3_cns.jpg";
import banner4_cn from "./banner4_cn.jpg";
import banner4_en from "./banner4_en.jpg";
import banner4_cns from "./banner4_cns.jpg";
import banner5_cn from "./banner5_cn.jpg";
import banner5_en from "./banner5_en.jpg";
import banner5_cns from "./banner5_cns.jpg";
import binanceRec from "./binanceTrxImg.jpeg";
import itsTrxRec from "./itsTrxImg.jpeg";
import referenceImg from "./reference.png";

export const addressIcon = address;
export const binanceIcon = binance;
export const coinsIcon = coins;
export const comunityIcon = comunity;
export const customer_serviceIcon = customer_service;
export const emailIcon = email;
export const forced_liquidationIcon = forced_liquidation;
export const Group_385Icon = Group_385;
export const Group_1705Icon = Group_1705;
export const iconIcon = icon;
export const LockIcon = Lock;
export const noDataIcon = noData;
export const not_foundIcon = not_found;
export const pre_stopIcon = pre_stop;
export const profileIcon = profile;
export const robotIcon = robot;
export const strategy_settingIcon = strategy_setting;
export const upload_imageIcon = upload_image;
export const usdtIcon = usdt;
export const comingSoonImg = comingSoon;
export const website_maintenanceImg = website_maintenance;
export const count_down_waitingImg = count_down_waiting;
export const warningImg = warning;
export const sandClockLoaderGif = sandClockLoader;
export const notificationImg = notification_icon;
export const giftRewardImg = giftReward;
export const exploreCommunityImg = exploreCommunity;
export const nodeTeam2Img = nodeTeam2;
export const register2Img = register2;
export const package2Img = package2;
export const incomeCap2Img = incomeCap2;
export const directReward2Img = directReward2;
export const placementReward2Img = placementReward2;
export const leaderReward2Img = leaderReward2;
export const news2Img = news2;
export const video2Img = video2;
export const congraImg = congra;
export const binanceWordImg = binanceWord;
export const ftxWordImg = ftxWord;
export const homenavIconImg = homenavIcon;
export const profilenavIconImg = profilenavIcon;
export const homeActiveImg = homeActive;
export const profileActiveImg = profileActive;
export const androidImg = android;
export const binanceSmallIconImg = binanceSmallIcon;
export const ftxIconImg = ftxIcon;
export const ftxWhiteImg = ftxWhite;
export const logoutIconImg = logoutIcon;
export const bellIconImg = bellIcon;
export const loginBgImg = loginBg;
export const vipRewardImg = vipReward;
export const addressWhiteIconImg = addressWhiteIcon;
export const agreementIconImg = agreementIcon;
export const settingWhiteImg = settingWhite;
export const csWhiteImg = csWhite;
export const threeDotImg = threeDot;
export const speakerImg = speaker;
export const newLogoImg = newLogo;
export const level0Img = level0;
export const level1Img = level1;
export const level2Img = level2;
export const level3Img = level3;
export const level4Img = level4;
export const level5Img = level5;
export const level6Img = level6;
export const level7Img = level7;
export const apiNewIconImg = apiNewIcon;
export const assetNewIconImg = assetNewIcon;
export const communiryNewIconImg = communiryNewIcon;
export const revenueNewIconImg = revenueNewIcon;
export const infoNewIconImg = infoNewIcon;
export const downloadNewIconImg = downloadNewIcon;
export const faqNewIconImg = faqNewIcon;
export const simulatorNewIconImg = simulatorNewIcon;
export const robotBannerImg = robotBanner;
export const robotNavImg = robotNav;
export const robotActiveNavImg = robotActiveNav;
export const performanceNavImg = performanceNav;
export const performanceActiveNavImg = performanceActiveNav;
export const summaryBack3Img = summaryBack3;
export const blueBanner2Img = blueBanner2;
export const blueBanner3Img = blueBanner3;
export const blueBanner4Img = blueBanner4;
export const blueBanner5Img = blueBanner5;
export const themeBackgroundImg = themeBackground;
export const menuIconImg = menuIcon;
export const closeMenuIconImg = closeMenuIcon;
export const walletIconImg = walletIcon;
export const tokocryptologoImg = tokocryptologo;
export const documentImg = document;
export const loginLogoImg = loginLogo;
export const robotNotifyImg = robotNotify;
export const globalLanguageImg = globalLanguage;
export const notEnoughImg = notEnough;
export const binanceImg = binance;
export const tokocrytoLogo2Img = tokocrytoLogo2;
export const banner1_cnImg = banner1_cn;
export const banner1_enImg = banner1_en;
export const banner1_cnsImg = banner1_cns;
export const banner2_cnImg = banner2_cn;
export const banner2_enImg = banner2_en;
export const banner2_cnsImg = banner2_cns;
export const banner3_cnImg = banner3_cn;
export const banner3_enImg = banner3_en;
export const banner3_cnsImg = banner3_cns;
export const banner4_cnImg = banner4_cn;
export const banner4_enImg = banner4_en;
export const banner4_cnsImg = banner4_cns;
export const banner5_cnImg = banner5_cn;
export const banner5_enImg = banner5_en;
export const banner5_cnsImg = banner5_cns;
export const binanceTrxImge = binanceRec;
export const itsTrxImg = itsTrxRec;
export const referenceIcon = referenceImg;
