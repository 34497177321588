import { InfoCircleOutlined } from "@ant-design/icons";
import { useLocation } from "@reach/router";
import { Button, Modal } from "antd";
import { cloneDeep, forEach, get, includes, isEmpty, map } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { feathers } from "../../../api/feathers_rest";
import getProfileAlert from "../../../newApi/alert/getProfileAlert";
import {
  triggerModal,
  updateGlobalModalVisible,
} from "../../../redux/actions/app-actions";
import { useTranslation } from "../../../locales/useTranslation";
import { forIn } from "lodash";
import { sourceKey } from "../../../locales/config";

// markup
const AlertModal = (props) => {
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const profile = get(props.user, "user.profile");
  const { t } = useTranslation();
  const accessKey = get(props.user, "accessKey");
  const [alertData, setAlertData] = useState([]);
  const [modalVisible, setModalVisible] = useState([]);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    if (!isEmpty(profile)) {
      getProfileAlert(
        "all",
        0,
        {
          profileId: get(profile, "_id") || "",
          isAlert: 0,
        },
        accessKey
      )
        .then((res) => {
          let data = get(res, "data");
          // console.log(data)

          setAlertData(data);
          let visibleAlert = [];

          forEach(data, (item, index) => {
            visibleAlert.push(true);
          });
          setModalVisible(visibleAlert);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const cancelAlert = (item, index, closeOnly = false) => {
    let _id = get(item, "_id");
    let tempVisible = cloneDeep(modalVisible);
    tempVisible[index] = false;

    if (closeOnly === true) {
      setModalVisible(tempVisible);
    } else {
      if (!isEmpty(_id)) {
        feathers("profile-alert", accessKey)
          .patch(_id, { isAlert: 1 })
          .then((res) => {
            setModalVisible(tempVisible);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  return (
    <React.Fragment>
      {map(alertData, (item, index) => {
        let alertType = get(item, "alertType");
        let parameter = get(item, "parameter");
        let title = get(item, "title");
        let content = "";

        if (includes(title, "Top Up")) {
          content = t("topupPopUp", sourceKey.home);
        } else if (includes(title, "Api Key")) {
          content = t("apiKeyPopUp", sourceKey.home);
        }

        if (!isEmpty(content)) {
          forIn(parameter, (value, key) => {
            if (value) {
              // console.log("here");
              content = content?.replace(`$${key}`, value);
            }
          });
        }

        return (
          <Modal
            // title={get(item, "title")}
            wrapClassName="no-padding-modal-body modal-body-background-transparent"
            visible={modalVisible[index]}
            // closable={false}
            maskClosable={false}
            onCancel={() => {
              cancelAlert(item, index, true);
            }}
            // cancelText="Confirm"
            footer={null}
            okButtonProps={{ style: { display: "none" } }}
          >
            {get(item, "alertType") === "alert" ? (
              <div
                className="bg-main-color-gradient  px-5 py-10 "
                style={{ borderRadius: 5 }}
              >
                <div className="flex justify-start m-4">
                  <div className="mr-4">
                    <InfoCircleOutlined style={{ fontSize: 30 }} />
                  </div>

                  <div className="">
                    <div className=" text-xl font-semibold">
                      {t("infoAlert", sourceKey.home)}!
                    </div>
                    <div className="text-base">
                      {!isEmpty(get(item, "subtitle")) ? (
                        <>
                          {get(item, "subtitle")}
                          <br />
                        </>
                      ) : (
                        ""
                      )}{" "}
                      {t(get(item, "content"), sourceKey.home)}
                    </div>
                  </div>
                </div>
                <div className="flex justify-end">
                  <Button
                    className=" input-border"
                    // size="large"
                    onClick={(e) => {
                      cancelAlert(item, index);
                    }}
                  >
                    {t("confirm")}
                  </Button>
                </div>
              </div>
            ) : (
              <div
                className="lightgreybox-bg-color px-5 py-10 "
                style={{ borderRadius: 5 }}
              >
                <div className="flex justify-start m-4">
                  <div className="mr-4">
                    <InfoCircleOutlined style={{ fontSize: 30 }} />
                  </div>

                  <div className="">
                    <div className=" text-xl font-semibold">
                      {t("info", sourceKey.home)}{" "}
                    </div>
                    <div className="text-base">{content}</div>
                  </div>
                </div>
                <div className="flex justify-end">
                  <Button
                    className=" input-border"
                    // size="large"
                    onClick={(e) => {
                      cancelAlert(item, index);
                    }}
                  >
                    {t("confirm")}
                  </Button>
                </div>
              </div>
            )}
          </Modal>
        );
      })}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  app: state.app,
});

const mapDispatchToProps = {
  updateGlobalModalVisible,
  triggerModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(AlertModal);
