import Enlocale from "antd/es/locale/en_GB";
import Indolocale from "antd/es/locale/id_ID";
import Cnslocale from "antd/es/locale/zh_CN";
import Cnlocale from "antd/es/locale/zh_TW";
import { get, isEmpty, toNumber } from "lodash";
import { sourceKey } from "../locales/config";
import { formatDecimalNumber } from "./common-function";
import { profileTransactionsAmountType } from "./profileTransactions";

export function checkIsActivated(profile) {
  if (!isEmpty(profile)) {
    return (
      checkIsSufficientActivationCode(profile) &&
      checkIsSufficientValidBalance(profile)
    );
  }

  return false;
}

export function checkIsSufficientBalance(profile) {
  if (!isEmpty(profile)) {
    let balanceUsdt = toNumber(get(profile, "balanceUsdt"));
    return balanceUsdt > 0;
  }

  return false;
}
export function checkIsSufficientValidBalance(profile) {
  if (!isEmpty(profile)) {
    let balanceUsdt = toNumber(get(profile, "balanceUsdt"));
    let balanceVsdt = toNumber(get(profile, "balanceVsdt"));
    return balanceUsdt + balanceVsdt > 0;
  }
  return false;
}
export function checkIsSufficientActivationCode(profile) {
  if (!isEmpty(profile)) {
    let expiredAt = toNumber(get(profile, "expiredAt"));
    return expiredAt > 0;
  }

  return false;
}

export const languages = [
  {
    value: "en",
    title: "English",
  },
  {
    value: "cn",
    title: "中文（繁體）",
  },
  {
    value: "cns",
    title: "中文（简体）",
  },
  {
    value: "indo",
    title: "Indonesia",
  },
];

export function languageToNumber(lang) {
  switch (lang) {
    case "en":
      return 0;
    case "cns":
    case "cn":
      return 1;
    case "indo":
      return 2;
    default:
      return 0;
  }
}

export function getLocalForAntd(lang) {
  switch (lang) {
    case "en":
      return Enlocale;
    case "cns":
      return Cnslocale;
    case "cn":
      return Cnlocale;
    case "indo":
      return Indolocale;
    default:
      return Enlocale;
  }
}

export const allRobotsActionsTypes = {
  tempSell: "tempSell",
  stopBuy: "stopBuy",
  restart : "restart",
  autoLink: "autoLink",
  multiLink: "multiLink"
}

export function convertPrestopToNumber(value){
  switch (value) {
    case allRobotsActionsTypes.tempSell:
      return 1;
    case allRobotsActionsTypes.stopBuy:
      return 2;
    case "":
      return 0;
    default:
      break;
    // return 0;
  }
}

export function convertVsdtTranslation(translation, record) {
  let trxType = get(record, "trxType");
  let amountType = get(record, "amountType");
  let title = "";

  switch (amountType) {
    case profileTransactionsAmountType.itsv:
      if (trxType === "OUT") {
        title = ` ${translation("deductVSDT2", sourceKey.highFrequency)}   ${formatDecimalNumber(record?.amount || 0, 2)}`;
      } else {
        // title = "compesated2";
        title = ` ${translation("compesated2", sourceKey.highFrequency)} `;
      }
      break;

    case profileTransactionsAmountType.vsdt:
      if (trxType === "OUT") {
        // title = `deductVSDT`;
        title = `
        ${translation("deductVSDT", sourceKey.highFrequency)} 
        ${formatDecimalNumber(record?.amount || 0, 2)}
        `;
      } else {
        // title = "compesated";
        title = ` ${translation("compesated", sourceKey.highFrequency)} `;

      }
      break;
    default:
      break;
  }

  return title;
}

export function convertNumberToDateCharacter(number) {
  switch (number) {
    case 1:
      return "day";
    case 2:
      return "month";
    case 3:
      return "week";
    default:
      break;
    // return 0;
  }
}
