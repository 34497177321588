import { apiUrl, routePrefix, _axios_base_get_list } from "..";

const PAGESIZE = 30;

export default function getProfiles(
  limit = PAGESIZE,
  skip = 0,
  query = {},
  accessKey = ""
) {
  return _axios_base_get_list(
    `${apiUrl}/${routePrefix.profile}/getProfiles`,
    limit,
    skip,
    query,
    accessKey
  );
}
