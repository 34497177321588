import allCnJson from "./cn";
import allCnsJson from "./cns";
import allEnJson from "./en";
import allIndoJson from "./indo";

export const allTranslationJson = {
  cn: {
    ...allCnJson,
  },
  cns: {
    ...allCnsJson,
  },
  en: {
    ...allEnJson,
  },
  indo: {
    ...allIndoJson,
  },
};
