import { useLocation } from "@reach/router";
import { Button } from "antd";
import { navigate } from "gatsby-link";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { notEnoughImg } from "../../../images";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getProfiles from "../../../newApi/profile/getProfiles";
import {
  triggerModal,
  updateGlobalModalTimer,
  updateGlobalModalVisible,
} from "../../../redux/actions/app-actions";
import { routes } from "../../../route";
import ConfirmationModal from "./ConfirmationModal";

let timeoutFunc;

// markup
const MessageModal = (props) => {
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [profile, setProfile] = useState({});
  const { t } = useTranslation();
  useEffect(() => {
    setVisible(props.app.modalVisible === true);
  }, [props]);

  useEffect(() => {}, [visible]);

  useEffect(() => {
    // getRobots("all", 0, {
    //   profileId: get(props.user, "user.profile._id"),
    //   errorNotify: robotStatusValue.inactiveAPIKeyError,
    // })
    //   .then((res) => {
    //     if (get(res, "data.length") > 0) {
    //       props.triggerModal({
    //         type: "apiKeyRobotError",
    //         redirection: routes.apiKey.to(),
    //       });
    //     }
    //     forEach(get(res, "data"), (item) => {
    //       if (get(item, "_id")) {
    //         feathers("robots").remove(item._id);
    //       }
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, []);

  useEffect(() => {
    getProfiles(1, 0, {
      _id: get(props.user, "user.profile._id"),
    })
      .then((res) => {
        setProfile(get(res, "data[0]"));
      })
      .catch((err) => {
        //   console.log(err);
      });
  }, []);

  const _renderContent = (type, app) => {
    switch (type) {
      case "apiKey":
        return (
          <React.Fragment>
            <div className="mb-5">
              <div className=" flex justify-center items-center text-base">
                {/* API 还未绑定 */}
                {t("apiNotBind", sourceKey.profile)}
              </div>
              {/* <div className=" flex justify-center items-center text-base">
                API Not Bind
              </div> */}
            </div>
          </React.Fragment>
        );
        break;
      case "apiKeyRobotError":
        return (
          <React.Fragment>
            <div className="mb-5">
              {/* <div className=" flex justify-center items-center text-base">
                API 还未绑定，已删除机器人
              </div> */}
              <div className=" flex justify-center items-center text-base">
                {t("apiNotBindDelete", sourceKey.profile)}
              </div>
            </div>
          </React.Fragment>
        );
        break;
      case "paymentPasswordOr2FA":
        return (
          <React.Fragment>
            <div className="mb-5">
              {/* <div className=" flex justify-center items-center text-base">
                支付密码/谷歌验证码未设置
              </div> */}
              <div className=" flex justify-center items-center text-base">
                {t("noGoogle2FA", sourceKey.profile)}
              </div>
            </div>
          </React.Fragment>
        );
        break;
      case "appBalance":
        return (
          <React.Fragment>
            <div className="mb-5">
              {/* <div className=" flex justify-center items-center text-base">
                当前资产少于200usdt 无法升级, 请前往充值
              </div> */}
              <div className=" flex justify-center items-center text-base">
                {t("lessAsset", sourceKey.profile)}
              </div>
            </div>
          </React.Fragment>
        );
        break;
      // case "invalidActivationCode":
      //   return (
      //     <React.Fragment>
      //       <div className="mb-5">
      //         <div className=" flex text-base text-left">
      //           请尽快充值资产或激活码避免当前交易被强制关单
      //         </div>
      //         <div className=" flex  text-base text-left">
      //           Please kindly top up asset or activation code to avoid
      //           interruption of trading
      //         </div>
      //         <div className=" flex  text-base text-left font-semibold mt-3">
      //           注：
      //         </div>
      //         <div className=" flex  text-base text-left">
      //           当资产或激活码归零后， 系统只给与24 小时内补给，
      //           若超出时限，系统将自动平仓所有交易， 用户自行承担损失
      //         </div>
      //         <div className=" flex  text-base text-left font-semibold mt-3">
      //           Reminder：
      //         </div>
      //         <div className=" flex text-base mb-3  text-left">
      //           System will only allow top up within 24hours if asset or
      //           activation code is negative value.
      //           <br />
      //           Failure to accomplish , all current position will be forced
      //           liquidised and all losses at user’s risk
      //         </div>
      //       </div>
      //     </React.Fragment>
      //   );
      //   break;
      case "binanceApiKeyInvalidError":
        return (
          <React.Fragment>
            <div className="mb-5">
              <div className=" flex  text-base text-left">
                {t("binanceApiNotif1", sourceKey.profile)}
              </div>

              <div className=" flex  text-base text-left font-semibold mt-3">
                {t("reminder", sourceKey.profile)}:
              </div>
              <div className=" flex text-base mb-3  text-left">
                {t("binanceApiNotif2", sourceKey.profile)}
              </div>
            </div>
          </React.Fragment>
        );
        break;
      case "tokocryptoApiKeyInvalidError":
        return (
          <React.Fragment>
            <div className="mb-5">
              <div className=" flex  text-base text-left">
                {t("tokoApiNotif1", sourceKey.profile)}
              </div>

              <div className=" flex  text-base text-left font-semibold mt-3">
                {t("reminder", sourceKey.profile)}:
              </div>
              <div className=" flex text-base mb-3  text-left">
                {t("binanceApiNotif2", sourceKey.profile)}
              </div>
            </div>
          </React.Fragment>
        );
        break;
      case "ftxApiKeyInvalidError":
        return (
          <React.Fragment>
            <div className="mb-5">
              <div className=" flex  text-base text-left">
                {t("ftxApiNotif1", sourceKey.profile)}
              </div>

              <div className=" flex  text-base text-left font-semibold mt-3">
                {t("reminder", sourceKey.profile)}:
              </div>
              <div className=" flex text-base mb-3  text-left">
                {t("ftxApiNotif2", sourceKey.profile)}
              </div>
            </div>
          </React.Fragment>
        );
        break;
      case "walletLimit":
        return (
          <React.Fragment>
            <div className="mb-5">
              <div>{t("gentleReminder", sourceKey.highFrequency)}:</div>
              <div className=" flex  text-base text-left">
                {t("walletNotify", sourceKey.profile).replace(
                  `$exchange`,
                  get(app, "exchange")
                )}
              </div>
            </div>
          </React.Fragment>
        );
        break;
      case "suspend":
        return (
          <React.Fragment>
            <div className="">
              <div className="flex justify-start">
                <span className="px-1 py-0.5 rounded-2xl bg-white">
                  <img src={notEnoughImg} width={60} height={60} />
                </span>
                <span className="ml-4 text-lg font-semibold flex justify-center items-center">
                  {t("accountSuspend", sourceKey.profile)}
                </span>
              </div>
              <div className=" flex mt-2 text-base text-left">
                {t("suspend", sourceKey.profile)}
              </div>
              <div className=" flex  text-base text-left">
                {t("suspend2", sourceKey.profile)}
              </div>
              <div className=" flextext-base text-left">
                {t("suspend3", sourceKey.profile)}
              </div>

              <div className="flex justify-center mt-4">
                <Button
                  block
                  className="main-button-color input-border"
                  size="large"
                  onClick={(e) => {
                    navigate(routes.rewardExplore.to());
                    props.updateGlobalModalVisible(false);
                  }}
                >
                  {t("proceed")}
                </Button>
              </div>
            </div>
          </React.Fragment>
        );
        break;
      case "robotStatus":
        return (
          <React.Fragment>
            <div className="mb-5">
              <div className=" flex  text-base text-left">
                {t("qualifiedRewards")}
              </div>
              {/* <div className=" flex  text-base text-left font-semibold mt-3">
                Reminder：
              </div>
              <div className=" flex text-base mb-3  text-left">
                If you miss out on profits due to this issue, RT Solution will
                not be responsible for any user's loss.
              </div> */}
            </div>
          </React.Fragment>
        );
        break;
      case "lessCap":
        return (
          <React.Fragment>
            <div className="mb-5">
              <div className=" flex  mb-2 text-left">
                {/* {t("lessCapacity")} */}
                {t("gentleReminder", sourceKey.highFrequency)}:

              </div>
              <div>
                {t('rememberTopUp',sourceKey.profile)}
              </div>
              
              {/* <div className=" flex  text-base text-left font-semibold mt-3">
                Reminder：
              </div>
              <div className=" flex text-base mb-3  text-left">
                If you miss out on profits due to this issue, RT Solution will
                not be responsible for any user's loss.
              </div> */}
            </div>
          </React.Fragment>
        );

      default:
        return;
        break;
    }
  };

  const _renderConfirmationButton = (type, redirect = "") => {
    switch (type) {
      case "walletLimit":
        return (
          <React.Fragment>
            <Button
              block
              className="main-button-color input-border"
              size="large"
              onClick={(e) => {
                navigate(redirect || routes.apiKey.to());
                props.updateGlobalModalVisible(false);
              }}
            >
              {t("proceed")}
            </Button>
          </React.Fragment>
        );
      case "apiKey":
        return (
          <React.Fragment>
            <Button
              block
              className="main-button-color input-border"
              size="large"
              onClick={(e) => {
                navigate(redirect || routes.apiKey.to());
                props.updateGlobalModalVisible(false);
              }}
            >
              {t("proceed")}
            </Button>
          </React.Fragment>
        );
        break;
      case "apiKeyInvalidError":
      case "apiKeyRobotError":
        return (
          <React.Fragment>
            <Button
              block
              className="main-button-color input-border"
              size="large"
              onClick={(e) => {
                navigate(redirect || routes.apiKey.to());
                props.updateGlobalModalVisible(false);
              }}
            >
              {t("proceed")}
            </Button>
          </React.Fragment>
        );
        break;
      case "paymentPasswordOr2FA":
        return (
          <React.Fragment>
            <Button
              block
              className="main-button-color input-border"
              size="large"
              onClick={(e) => {
                navigate(redirect || routes.setting.to());
                props.updateGlobalModalVisible(false);
              }}
            >
              {t("proceed")}
            </Button>
          </React.Fragment>
        );
        break;
      case "appBalance":
        return (
          <React.Fragment>
            <Button
              block
              className="main-button-color input-border"
              size="large"
              onClick={(e) => {
                navigate(redirect || routes.deposit.to());
                props.updateGlobalModalVisible(false);
              }}
            >
              {t("proceed")}
            </Button>
          </React.Fragment>
        );
        break;
      // case "invalidActivationCode":
      //   //return null;
      //   return (
      //     <React.Fragment>
      //       <Button
      //         block
      //         className="main-button-color input-border"
      //         size="large"
      //         onClick={(e) => {
      //           navigate(redirect || routes.activationCode.to());
      //           props.updateGlobalModalVisible(false);
      //         }}
      //       >
      //         前往激活 Proceed to Activate
      //       </Button>
      //     </React.Fragment>
      //   );
      //   break;

      default:
        return;
        break;
    }
  };

  return (
    <React.Fragment>
      <ConfirmationModal
        visible={visible}
        onClose={() => {
          if (
            get(props, "app.modalType") === "robotStatus" ||
            get(props, "app.modalType") === "lessCap"
          ) {
            props.updateGlobalModalTimer(false);
          } else {
            props.updateGlobalModalVisible(false);
          }
        }}
        confirmButton={() =>
          _renderConfirmationButton(
            get(props, "app.modalType"),
            get(props, "app.modalRedirection")
          )
        }
      >
        {_renderContent(get(props, "app.modalType"), get(props, "app"))}
      </ConfirmationModal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  app: state.app,
});

const mapDispatchToProps = {
  updateGlobalModalVisible,
  updateGlobalModalTimer,
  triggerModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(MessageModal);
