import { get, isEmpty, toNumber } from "lodash";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "../../../locales/useTranslation";
import localStorage from "local-storage";
import getSessionInfo from "../../../newApi/profile/getSessionInfo";
import { sessionPass } from "../../../utilities/startUp";
import { message } from "antd";
import Randomstring from "randomstring";

// markup
const SessionInfoCheck = (props) => {
    const profile = get(props.user, "user.profile");
    const { t } = useTranslation();

    let sessionInfo = localStorage.get("sessionInfo")

    useEffect(() => {
        let currentSessionTimestamp = toNumber(sessionInfo?.sessionTime) || 0

        if ((new Date().getTime() - currentSessionTimestamp) > 60 * 60 * 1000 || currentSessionTimestamp === 0) {
            sessionInfo = Randomstring.generate();
            if (!isEmpty(profile)) {
                sessionInfo = profile?._id
            }

            getSessionInfo({ sessionHint: sessionInfo }, { "session-pass": sessionPass })
                .then((res) => {
                    // console.log("res", res);
                    let data = res?.data?.data;
                    localStorage.set("sessionInfo", data);
                })
                .catch((err) => {
                    message.error("Session Error");
                })
        }

    }, [])


    return (
        <React.Fragment>

        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    user: state.user,
});

const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(SessionInfoCheck);
