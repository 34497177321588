import { _base_axios_post, apiUrl } from "..";
import client from "../../../feathers";

export default function validateSecondPassword(query = {}) {
  // return _base_axios_post(`${apiUrl}/main/createUser`, query)
  return _base_axios_post(
    `${apiUrl}/user/validateSecondPassword`,
    query
  );
}
