import { _base_axios_post, apiUrl } from "..";

export default function getSessionInfo(query = {}, header) {
    return _base_axios_post(
        `${apiUrl}/session/getSessionInfo`,
        query,
        "",
        header
    );
}
