import axios from "axios";
import { get, isPlainObject, isString } from "lodash";
import client from "../../feathers";

export const feathers = (service, accessKey) => {
  if (!isString(service)) {
    return new Promise((resolve, reject) => {
      reject({
        message: "Service Not Found!",
      });
    });
  }

  return {
    authenticate: async (query, accessKey) => {
      return await axios
        .post(
          `${client.io.io.uri}authentication`,
          {
            ...query,
          },
          {
            headers: {
              Authorization: "Bearer " + accessKey,
            },
          }
        )
        .then((res) => {
          return res.data;
        })
        .catch(function (error) {
          return new Promise((resolve, reject) => {
            reject({
              error: error,
              message: "Something went wrong!",
            });
          });
        });
    },
    find: async (query) => {
      return await axios
        .get(`${client.io.io.uri}${service}`, {
          params: {
            ...query,
          },
          headers: {
            Authorization: "Bearer " + accessKey,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch(function (error) {
          return new Promise((resolve, reject) => {
            reject({
              error: error,
              message: "Something went wrong!",
            });
          });
        });
    },
    get: async (_id) => {
      return await axios
        .get(`${client.io.io.uri}${service}/${_id}`, {
          params: {},
          headers: {
            Authorization: "Bearer " + accessKey,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch(function (error) {
          return new Promise((resolve, reject) => {
            reject({
              error: error,
              message: "Something went wrong!",
            });
          });
        });
    },
    create: async (data) => {
      if (!isPlainObject(data)) {
        data = {};
      }

      return await axios
        .post(
          `${client.io.io.uri}${service}`,
          {
            ...data,
          },
          {
            headers: {
              Authorization: "Bearer " + accessKey,
            },
          }
        )
        .then((res) => {
          return res.data;
        })
        .catch(function (error) {
          return new Promise((resolve, reject) => {
            reject({
              error: error,
              message: get(error, "response.data.message"),
            });
          });
        });
    },
    update: async (_id, data) => {
      if (!isPlainObject(data)) {
        data = {};
      }

      return await axios
        .put(
          `${client.io.io.uri}${service}/${_id}`,
          {
            ...data,
          },
          {
            headers: {
              Authorization: "Bearer " + accessKey,
            },
          }
        )
        .then((res) => {
          return res.data;
        })
        .catch(function (error) {
          return new Promise((resolve, reject) => {
            reject({
              error: error,
              message: "Something went wrong!",
            });
          });
        });
    },
    patch: async (_id, data) => {
      if (!isPlainObject(data)) {
        data = {};
      }

      return await axios
        .patch(
          `${client.io.io.uri}${service}/${_id}`,
          {
            ...data,
          },
          {
            headers: {
              Authorization: "Bearer " + accessKey,
            },
          }
        )
        .then((res) => {
          return res.data;
        })
        .catch(function (error) {
          return new Promise((resolve, reject) => {
            reject({
              error: error,
              message:
                get(error, "response.data.message") || "Something went wrong!",
            });
          });
        });
    },
    remove: async (_id) => {
      return await axios
        .delete(
          `${client.io.io.uri}${service}/${_id}`,
          {},
          {
            headers: {
              Authorization: "Bearer " + accessKey,
            },
          }
        )
        .then((res) => {
          return res.data;
        })
        .catch(function (error) {
          return new Promise((resolve, reject) => {
            reject({
              error: error,
              message: "Something went wrong!",
            });
          });
        });
    },
  };
};
