import { get, map } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// import getUserSummary from "../../../newApi/rewardExplore/getUserSummary";
import moment from "moment";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getUserSummary from "../../../newApi/pairing/getUserSummary";
import { routes } from "../../../route";
import { formatDecimalNumber } from "../../../utilities/common-function";
import { numberDecimal, timeZone } from "../../../utilities/startUp";
import moment2 from "moment-timezone";



//let displayRobot = [];

// markup
const RewardListing = (props) => {
  // const location = useLocation();
  const { t } = useTranslation();
  // const [form] = useForm();
  // const [loading, setLoading] = useState(false);
  const [todayData, setTodayData] = useState({});
  const [data, setData] = useState({});
  let accessKey = get(props.user, "accessKey");
  //let profile = get(props.user.user, "profile");
  const user = get(props, "user");

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    // setArrayLoading(true);
    // console.log(user)
    // console.log(get(user, "user.pairingUser._id"));
    if (get(user, "user.pairingUser._id")) {
      // console.log("getData");
      getUserSummary(
        "all",
        0,
        {
          userId: get(user, "user.pairingUser._id"),
          dateFrom: moment2().tz(timeZone).startOf("d").format(),
          dateTo: moment2().tz(timeZone).endOf("d").format(),
        },
        accessKey
      )
        .then((res) => {
          // console.log({ res });
          setTodayData(get(res, "data"));
        })
        .catch((err) => {
          console.log(err);
        });

      getUserSummary(
        "all",
        0,
        {
          userId: get(user, "user.pairingUser._id"),
        },
        accessKey
      )
        .then((res) => {
          // console.log({ res });
          setData(get(res, "data"));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const types = [
    {
      text: t("directReward", sourceKey.pairing),
      textCn: "直推",
      valueIndex: "totalBonus",
      path: routes.directReward.to(),
    },
    {
      text: t("placementReward", sourceKey.pairing),
      textCn: "对碰",
      valueIndex: "totalPairingBonus",
      path: routes.placementReward.to(),
    },
    {
      text: t("leaderReward", sourceKey.pairing),
      textCn: "领导",
      valueIndex: "totalLeaderBonus",
      path: routes.leaderReward.to(),
    },
    // {
    //   text: t("vipReward", sourceKey.pairing),
    //   textCn: "VIP 奖励",
    //   valueIndex: "totalCompanyBonus",
    //   path: routes.vipReward.to(),
    // },
  ];

  const _renderTypes = () => {
    return (
      <>
        <div className="">
          <div className="flex mx-4 px-4">
            <div className="inline-block w-1/3 flex justify-end"></div>

            <div className="inline-block w-2/3 flex pl-4">
              <span className="inline-block w-1/2 flex justify-end">
                {t("today", sourceKey.pairing)}
              </span>
              <span className="inline-block w-1/2 flex justify-end">
                {t("total", sourceKey.pairing)}
              </span>
            </div>
          </div>
          {map(types, (item, index) => {
            return (
              <div
                className="flex mx-4 rounded-lg px-4 pb-1 theme-bg-color "
                // style={{ cursor: "pointer" }}
                onClick={() => {
                  // navigate(item.path);
                }}
              >
                <div className="inline-block w-1/3 flex justify-start ">
                  {/* {get(item, "textCn")}{" "}
                    <br /> */}
                  {get(item, "text")}
                </div>

                <div className="inline-block w-2/3 flex pl-4 text-base">
                  <span className="inline-block w-1/2 flex justify-end">
                    {formatDecimalNumber(
                      get(todayData, item.valueIndex) || 0,
                      numberDecimal
                    )}
                  </span>
                  <span className="inline-block w-1/2 flex justify-end">
                    {formatDecimalNumber(
                      get(data, item.valueIndex) || 0,
                      numberDecimal
                    )}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <React.Fragment>
      <div className="  ">
        {/* <div className="pt-4">{_renderProfit()}</div> */}
        {_renderTypes()}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  app: state.app,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(RewardListing);
