import { useLocation } from "@reach/router";
import { Drawer } from "antd";
import { Link, navigate } from "gatsby";
import { get, map } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  closeMenuIconImg,
  homenavIconImg,
  nodeTeam2Img,
  profilenavIconImg,
  robotBannerImg,
  themeBackgroundImg,
} from "../../../images";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import {
  clearModal,
  triggerModal,
  updateGlobalModalVisible,
} from "../../../redux/actions/app-actions";
import { updateActiveId } from "../../../redux/actions/product-actions";
import {
  logoutSuccessful,
  pairingLogin,
} from "../../../redux/actions/user-actions";
import { routes } from "../../../route";
import { navItems1, pairingItems1, rewardItems1 } from "../../general/config";
import DownloadPopUpModal from "./DownloadPopUpModal";
import RewardListing from "./RewardListing";
import PairingLoginModal from "../../rewardExplore/components/PairingLoginModal";
import moment from "moment";
// markup
const HomePage = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [downloadPopUpVisible, setDownloadPopUpVisible] = useState(false);
  const [loginVisible, setLoginVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  let accessKey = get(props.user, "accessKey");
  let user = get(props, "user.user");

  useEffect(() => {
    setVisible(props.visible === true);
    if (props.visible === true) {
      let user_access = get(props, "user");
      if (get(user_access, "pairingLogin") === false) {
        setLoginVisible(true);
      } else {
        let loginTime = get(user_access, "pairingLoginTime");
        loginTime = moment(new Date(loginTime));
        let expiredTime = loginTime.add(30, "minutes").toDate().getTime();
        let dateNow = new Date();

        if (dateNow.getTime() > expiredTime) {
          setLoginVisible(true);
        } else {
          //refresh
          props.pairingLogin(true);
        }
      }
    }
  }, [props.visible]);

  function close() {
    setVisible(false);
    setDownloadPopUpVisible(false);
    setLoginVisible(false);
    navigate(routes.home.to());
    if (props.onClose) {
      props.onClose();
    }
  }

  const generalItem = () => {
    return (
      <div className="whitegreybox-bg-color mt-2">
        <div className="grid grid-cols-8 gap-2 pt-1">
          {map(navItems1, (item, index) => {
            let bgColor = "navItem-bg-color";

            return (
              <div className={`col-span-2  flex justify-center rounded-2xl`}>
                {get(item, "popUpClick") ? (
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (get(item, "popUpClick") === "download") {
                        setDownloadPopUpVisible(true);
                      }
                    }}
                  >
                    <div
                      className="  justify-center items-center rounded-xl "
                      style={{ width: "100%" }}
                      key={`navItems-1-${index}`}
                    >
                      <div className="justify-center items-center flex ">
                        <div
                          className="justify-center items-center flex navItem-bg-color py-2 rounded-full"
                          style={{
                            width: "50.38px",
                            height: "50.38px",
                          }}
                        >
                          <img
                            src={item.icon}
                            style={{ width: 25, height: 22 }}
                          />
                        </div>
                      </div>

                      <div className="text-xs  mt-1 justify-center items-center flex">
                        {t(get(item, "text"), sourceKey.home)}
                      </div>
                    </div>
                  </div>
                ) : (
                  <Link to={item.path} state={{ prevPath: location.pathname }}>
                    <span
                      className="inline-block  rounded-xl py-1 "
                      style={{ width: "100%" }}
                      key={`navItems-1-${index}`}
                    >
                      <div className="justify-center items-center flex ">
                        <div
                          className="justify-center items-center flex navItem-bg-color py-2 rounded-full"
                          style={{
                            width: "50.38px",
                            height: "50.38px",
                          }}
                        >
                          <img
                            src={item.icon}
                            style={{ width: 25, height: 22 }}
                          />
                        </div>
                      </div>
                      <div className="text-xs  text-center ">
                        {t(get(item, "text"), sourceKey.home)}
                      </div>
                    </span>
                  </Link>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const pairingItem = () => {
    return (
      <div className="whitegreybox-bg-color mt-2 py-2">
        <div className="grid grid-cols-8 gap-2 pt-1">
          {map(pairingItems1, (item, index) => {
            let bgColor = "navItem-bg-color";

            return (
              <div className={`col-span-2  flex justify-center rounded-2xl`}>
                {
                  <Link to={item.path} state={{ prevPath: location.pathname }}>
                    <span
                      className="inline-block  rounded-xl py-1 "
                      style={{ width: "100%" }}
                      key={`navItems-1-${index}`}
                    >
                      <div className="justify-center items-center flex ">
                        <div
                          className="justify-center items-center flex navItem-bg-color py-2 rounded-full"
                          style={{
                            width: "50.38px",
                            height: "50.38px",
                          }}
                        >
                          <img
                            src={item.icon}
                            style={{ width: 25, height: 22 }}
                          />
                        </div>
                      </div>
                      <div className="text-xs  semibold text-center ">
                        {t(get(item, "value"), sourceKey.pairing)}
                      </div>
                    </span>
                  </Link>
                }
              </div>
            );
          })}

          <div className="col-span-8">
            <div className="mx-6">
              <Link
                to={routes.nodeTeam.to()}
                state={{ prevPath: location.pathname }}
              >
                <span
                  className="inline-block  rounded-xl py-1 "
                  style={{ width: "100%" }}
                  key={`navItems-1-1`}
                >
                  <div className="justify-center items-center flex navItem-bg-color py-2 rounded-full">
                    <img src={nodeTeam2Img} style={{ width: 30, height: 30 }} />
                  </div>
                  <div className="text-xs  semibold text-center ">
                    {t("nodeTeam", sourceKey.pairing)}
                  </div>
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const rewardItem = () => {
    return (
      <div className="whitegreybox-bg-color mt-2 py-2">
        <div className="grid grid-cols-8 gap-2 pt-1">
          {map(rewardItems1, (item, index) => {
            let bgColor = "navItem-bg-color";

            return (
              <div className={`col-span-2  flex justify-center rounded-2xl`}>
                {
                  <Link to={item.path} state={{ prevPath: location.pathname }}>
                    <span
                      className="inline-block  rounded-xl py-1 "
                      style={{ width: "100%" }}
                      key={`navItems-1-${index}`}
                    >
                      <div className="justify-center items-center flex ">
                        <div
                          className="justify-center items-center flex navItem-bg-color py-2 rounded-full"
                          style={{
                            width: "50.38px",
                            height: "50.38px",
                          }}
                        >
                          <img
                            src={item.icon}
                            style={{ width: 25, height: 22 }}
                          />
                        </div>
                      </div>
                      <div className="text-xs  semibold text-center ">
                        {t(get(item, "value"), sourceKey.pairing)}
                      </div>
                    </span>
                  </Link>
                }
              </div>
            );
          })}

          <div className="col-span-8">
            <div className="mx-6">
              <RewardListing />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Drawer
      width="100%"
      height="100%"
      className="body-no-padding"
      closable={false}
      visible={visible}
      // onClose={() => {
      //   close();
      // }}
    >
      <div className="flex justify-center">
        <div
          className=" min-h-screen relative"
          style={{
            minWidth: 300,
            maxWidth: 667,
            width: "100%",
            backgroundImage: `url(${themeBackgroundImg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            // height: 67,
          }}
        >
          <div className="" style={{ height: "105vh" }}>
            <div>
              <img
                src={robotBannerImg}
                className="w-full  object-cover object-center menuWave"
                style={{
                  height: "50px",
                }}
              />
            </div>
            <div className="mx-4 mb-6">
              {generalItem()}
              {pairingItem()}
              {rewardItem()}
            </div>
          </div>

          <DownloadPopUpModal
            visible={downloadPopUpVisible}
            onClose={() => {
              setDownloadPopUpVisible(false);
            }}
          />

          <PairingLoginModal
            visible={loginVisible}
            user={user}
            onSuccess={() => {
              props.pairingLogin(true);
            }}
            onClose={() => {
              close();
            }}
          />

          <div className="bottom-appbar w-screen" style={{ maxWidth: 667 }}>
            <div class="tabs">
              <div class="tab is-active tab--left">
                <span
                  onClick={() => {
                    if (get(location, "pathname") === "/") {
                      close();
                    } else {
                      navigate(routes.home.to());
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <img src={homenavIconImg} width={25} height={25} />
                </span>
              </div>
              <div class="tab tab--fab">
                <div class="top">
                  <div
                    class="fab"
                    onClick={() => {
                      setVisible(false);
                      setDownloadPopUpVisible(false);
                      setLoginVisible(false);
                      if (props.onClose) {
                        props.onClose();
                      }
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={closeMenuIconImg} width={40} height={40} />
                  </div>
                </div>
              </div>
              <div class="tab tab--right">
                <span
                  onClick={() => {
                    navigate(routes.profile.to());
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <img src={profilenavIconImg} width={22} height={22} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  app: state.app,
});

const mapDispatchToProps = {
  updateActiveId,
  updateGlobalModalVisible,
  triggerModal,
  logoutSuccessful,
  clearModal,
  pairingLogin,
};
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
