import localStorage from "local-storage";
import { get, isString, isEmpty } from "lodash";
// import { useRouter } from 'next/router'
import { allTranslationJson } from "./index";

const defaultLocale = "indo";
export const useTranslation = () => {
  let locales = "";
  // if (typeof window !== "undefined") {
  locales = localStorage.get("locale") || "";
  // }
  const locale = isEmpty(locales) ? defaultLocale : locales;

  if (isEmpty(locales)) {
    // if (typeof window !== "undefined") {
    localStorage.set("locale", locale);
    // }
  }

  return {
    t: (term, key) => {
      const translation = get(
        allTranslationJson,
        `${locale}.["${isString(key) ? `${key}.` : ""}${term}"]`
      );

      return Boolean(translation) ? translation : term;
    },
    languages: locales,
    changeLanguage: (lang) => {
      // lang = locales.includes(lang || "") ? lang : defaultLocale;

      // if (typeof window !== "undefined") {
      localStorage.set("locale", lang);
      // }
      // console.log("result", lang);
      window.location.reload();
      // nextRouter.push(pathname, asPath, { locale: lang })
    },
    defaultLanguage: defaultLocale,
  };
};
