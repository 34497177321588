import { useLocation } from "@reach/router";
import { ConfigProvider, message } from "antd";
import axios from "axios";
import { Link, navigate } from "gatsby";
import { get, includes, indexOf, isEmpty, map, sumBy } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import client from "../../../../feathers";
import { useTranslation } from "../../../locales/useTranslation";
import getSymbolMarketStructure2 from "../../../newApi/cxxt/getSymbolMarketStructure2";
import getPairingUser from "../../../newApi/pairing/getPairingUser";
import getPlacements from "../../../newApi/placement/getPlacements";
import getProfiles from "../../../newApi/profile/getProfiles";
import { clearModal, triggerModal } from "../../../redux/actions/app-actions";
import {
  updateCoinStructure,
  updatePreviousPagination,
} from "../../../redux/actions/product-actions";
import {
  logoutSuccessful,
  updateLoginTime,
} from "../../../redux/actions/user-actions";
import { routes } from "../../../route";
import { openBinancePriceSocket } from "../../../utilities/binance";
import { getLocalForAntd } from "../../../utilities/profile";
import MenuDrawer from "../../homepage/pages/MenuDrawer";
import AlertModal from "./AlertModal";
import NotificationModal from "./NotificationModal";
import { homenavIconImg, menuIconImg, profilenavIconImg, themeBackgroundImg } from "../../../images";
import SessionInfoCheck from "./SessionInfoCheck";

// markup
const Layout = (props) => {
  const location = useLocation();
  const { t, languages } = useTranslation();
  const currentPath =
    location.pathname[location.pathname.length - 1] === "/" &&
      location.pathname.length > 1
      ? location.pathname.slice(0, -1)
      : location.pathname;
  const accessKey = get(props.user, "accessKey");
  const [loggedIn, setLoggedIn] = useState(false);
  const [profile, setProfile] = useState({});
  const [skipAlert, setSkipAlert] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);

  message.config({
    top: 10,
    duration: 1,
    maxCount: 1,
  });

  useEffect(() => {
    if (isEmpty(accessKey)) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }, [accessKey]);

  useEffect(() => {
    if (
      get(location, "pathname") != routes.login.to() &&
      get(location, "pathname") != routes.register.to() &&
      get(location, "pathname") != routes.forgotPassword.to()
    ) {
      if (
        includes(get(location, "pathname"), "email_verification") ||
        includes(get(location, "pathname"), "reset_password") ||
        includes(get(location, "pathname"), "register")
      ) {
        //skip authorization
      } else {
        axios
          .post(
            `${client.io.io.uri}feather-authenticate`,
            {},
            {
              // accessToken : accessKey,
              headers: {
                Authorization: "Bearer " + accessKey,
              },
            }
          )
          .then((res) => { })
          .catch(function (error) {
            // console.log(error);
            //message.error('Session Expired. Please Login Again');

            navigate(routes.login.to());
          });
      }
    }
    if (
      includes(get(location, "pathname"), "login") ||
      includes(get(location, "pathname"), "register") ||
      includes(get(location, "pathname"), "forgot_password") ||
      includes(get(location, "pathname"), "email_verification") ||
      includes(get(location, "pathname"), "reset_password")
    ) {
      setSkipAlert(true);
    } else {
      openBinancePriceSocket();
      if (isEmpty(props?.product?.coinUnitStructure)) {
        // if (location?.pathname === "/") {
        getSymbolMarketStructureData();
      }
      let profile = get(props, "user.user.profile");

      getProfiles(1, 0, {
        _id: profile?._id,
      })
        .then((res) => {
          // console.log(res);
          let data = res?.data;
          data = data[0];
          setProfile(data);
        })
        .catch((err) => {
          // console.log(err);
          message.error(err?.message);
        });
    }
  }, []);

  useEffect(() => {
    let user = get(props, "user");
    if (loggedIn & !isEmpty(profile)) {
      let loginTime = get(user, "loginTime");
      if (loginTime === null) {
        //logount
        logout();
      } else {
        loginTime = moment(new Date(loginTime));
        let expiredTime = loginTime.add(30, "minutes").toDate().getTime();
        let dateNow = new Date();
        if (dateNow.getTime() > expiredTime && profile?.nonAutoLogout !== 1) {
          // message.error(t("loginAgain"));
          logout();
        } else {
          let user2 = get(props, "user.user");
          let notifiedTime = new Date(get(props.app, "modalNotifyTime"));
          let currentTime = new Date();

          // let api_data = get(profile, "api[0]");
          let notify = false;

          if (notifiedTime !== null) {
            const diffInMs = Math.abs(currentTime - notifiedTime);
            const TransactionDayDiff = diffInMs / (1000 * 60 * 60 * 24);

            if (TransactionDayDiff >= 1) {
              notify = true;
            }
          } else {
            notify = true;
          }

          if (notify) {
            getPlacements(7, 0, {
              userId: get(user2, "profile.pairingUserId"),
            })
              .then((res) => {
                // console.log(res);
                let placement_data = get(res, "data");
                let staticUsed = sumBy(placement_data, "usedStaticCap") || 0;
                let staticTotal = sumBy(placement_data, "staticCap") || 0;
                let percent = (staticUsed / staticTotal) * 100;
                if (percent > 80) {
                  props.triggerModal({
                    type: "lessCap",
                  });
                }
              })
              .catch((err) => {
                // console.log(err);
              });

            getPairingUser(1, 0, { _id: get(user2, "profile.pairingUserId") })
              .then((res) => {
                // console.log(res);
                let data = get(res, "data");
                data = data[0];
                if (get(data, "status") === 1 && notify === true) {
                  props.triggerModal({
                    type: "robotStatus",
                  });
                }
              })
              .catch((err) => {
                // console.log(err);
              });
          }

          props.updateLoginTime();
        }

        // // loginTime = new Date(loginTime);
        // let current = new Date();
        // let expireTime = new Date(loginTime);
        // expireTime.setDate(expireTime.getDate() + 1);
        // current = moment(moment(current).format("YYYY-MM-DD"));
        // expireTime = moment(moment(expireTime).format("YYYY-MM-DD"));
        // if (current.isSameOrAfter(expireTime)) {
        //   // logout
        //   logout();
        // } else {
        // }
      }
    }
  }, [loggedIn, profile]);

  useEffect(() => {
    let user = get(props, "user.user");

    let profile_data = get(user, "profile");

    if (loggedIn) {
      if (
        includes(get(location, "pathname"), "login") ||
        includes(get(location, "pathname"), "forgot_password")
      ) {
      } else {
        if (
          isEmpty(get(profile_data, "nickname")) ||
          isEmpty(get(profile_data, "contactNo")) ||
          isEmpty(get(profile_data, "email"))
        ) {
          // message.info(t("completeProfile"), 5);
          navigate(
            routes.setting.to({
              type: "forceFill",
            })
          );
        }
      }
    }
  }, [loggedIn]);

  function getSymbolMarketStructureData() {
    // console.log("getData");
    getSymbolMarketStructure2()
      .then((res) => {
        // console.log("here")
        // console.log(res);
        let data = res?.data;
        props.updateCoinStructure(data);
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  function logout() {
    props.clearModal();
    props.logoutSuccessful();
    props.updatePreviousPagination({});
    // message.success("已登出! Logged out!");
    navigate(routes.login.to());
    if (typeof window !== `undefined`) {
      navigate(routes.home.to());
    }
  }

  return (
    <div className="flex justify-center">
      <SessionInfoCheck />
      <div
        className=" min-h-screen relative"
        style={{
          minWidth: 300,
          maxWidth: 667,
          width: "100%",
          backgroundImage: `url(${themeBackgroundImg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          // height: 67,
        }}
      >
        <div className="" style={{ minHeight: "105vh", color: "black" }}>
          <ConfigProvider locale={getLocalForAntd(languages)}>
            {props.children}
            <NotificationModal />
            {/* {skipAlert === false && loggedIn === true && <AlertModal />} */}
          </ConfigProvider>
        </div>

        {/* <div class="wrapper"> */}
        {/* <div class="page">Body</div> */}
        {loggedIn ? (
          <div className="bottom-appbar" style={{ maxWidth: 667 }}>
            <div class="tabs">
              <div class="tab is-active tab--left">
                <span
                  onClick={() => {
                    navigate(routes.home.to());
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <img src={homenavIconImg} width={25} height={25} />
                </span>
              </div>
              <div class="tab tab--fab">
                <div class="top">
                  <div
                    class="fab"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setMenuVisible(true);
                    }}
                  >
                    <img src={menuIconImg} width={33} height={33} />
                  </div>
                </div>
              </div>
              <div class="tab tab--right">
                <span
                  onClick={() => {
                    navigate(routes.profile.to());
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <img src={profilenavIconImg} width={22} height={22} />
                </span>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <MenuDrawer
        visible={menuVisible}
        onClose={() => {
          setMenuVisible(false);
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  app: state.app,
  product: state.product,
});

const mapDispatchToProps = {
  logoutSuccessful,
  clearModal,
  triggerModal,
  updateLoginTime,
  updatePreviousPagination,
  updateCoinStructure,
};
export default connect(mapStateToProps, mapDispatchToProps)(Layout);
