export let defaultExchangePlatform = "binance";
export let exchangePlatform = defaultExchangePlatform;
export let AllExchangePlatforms = ["binance", "tokocrypto"];
export let numberDecimal = 2;
export let apkLink = "https://indoits.com/apk/IndoITS.apk";
export let countryCode = "indonesia";
export let demoURl = "https://demo.indoits.com/authenticate"
export let demoInternalURl = "https://demo-internal.myits.co/authenticate"
// export let demoInternalURl = "http://localhost:8002/authenticate"
export let timeZone = "Asia/Kuala_Lumpur"
export let socketUrl = "https://socket.myits.app"

export const sessionPass = "38N9tBXe1vXidIz2";
