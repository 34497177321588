import React from "react";

import {
  DollarCircleOutlined,
  HomeOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import {
  apiIcon,
  apiNewIconImg,
  assetNewIconImg,
  communiryNewIconImg,
  directReward2Img,
  downloadNewIconImg,
  exploreCommunityImg,
  faqNewIconImg,
  homeActiveImg,
  homenavIconImg,
  incomeCap2Img,
  infoNewIconImg,
  leaderReward2Img,
  nodeTeam2Img,
  package2Img,
  performanceActiveNavImg,
  performanceNavImg,
  placementReward2Img,
  profileActiveImg,
  profilenavIconImg,
  referenceIcon,
  register2Img,
  revenueNewIconImg,
  robotActiveNavImg,
  robotIcon,
  robotNavImg,
  simulatorNewIconImg,
  vipRewardImg,
  walletIconImg,
} from "../../images";
import { routes } from "../../route";
import { mainColor } from "../../styles/config";
import { demoURl, demoInternalURl } from "../../utilities/startUp";

export const navBarItems = [
  {
    icon: <img src={homenavIconImg} width={25} height={25} />,
    activeIcon: <img src={homeActiveImg} width={25} height={25} />,
    text: "Home",
    value: "home",
    path: routes.home.to(),
  },
  {
    icon: <img src={robotNavImg} width={25} height={25} />,
    activeIcon: <img src={robotActiveNavImg} width={25} height={25} />,
    text: "Robot",
    value: "robot",
    path: routes.robot.to(),
  },
  {
    icon: <img src={performanceNavImg} width={25} height={25} />,
    activeIcon: <img src={performanceActiveNavImg} width={25} height={25} />,
    text: "Performance",
    value: "performance",
    path: routes.explore.to(),
  },
  {
    icon: <WalletOutlined style={{ color: "#CCCCCC", fontSize: "23px" }} />,
    activeIcon: (
      <WalletOutlined style={{ color: mainColor, fontSize: "23px" }} />
    ),
    text: "Simulator",
    value: "simulator",
    path: routes.transaction.to(),
  },
  // {
  //   icon: <img src={exploreIconImg} width={25} height={25} />,
  //   activeIcon: <img src={exploreActiveImg} width={25} height={25} />,
  //   text: "Explore",
  //   value: "explore",
  //   path: routes.rewardExplore.to(),
  // },
  // {
  //     icon: <FileTextOutlined style={{ color: 'white', fontSize: '25px' }} />,
  //     activeIcon: <FileTextOutlined style={{ color: mainColor, fontSize: '25px' }} />,
  //     text: 'Record',
  //     value: 'record',
  //     path: routes.billing.to(),
  // },
  // {
  //   // icon: <WalletOutlined style={{ color: "white", fontSize: "25px" }} />,
  //   icon: <img src={capImg} width={25} height={25} />,
  //   activeIcon: <img src={capActiveImg} width={25} height={25} />,
  //   text: "Billing",
  //   value: "billing",
  //   path: routes.incomeCapHome.to(),
  // },
  {
    icon: <img src={profilenavIconImg} width={22} height={22} />,
    activeIcon: <img src={profileActiveImg} width={22} height={22} />,
    text: "Profile",
    value: "profile",
    path: routes.profile.to(),
    childrenRoutes: [routes.setting.to()],
  },
];

export const navItems = [
  {
    icon: apiIcon,
    activeIcon: <HomeOutlined style={{ color: mainColor, fontSize: "25px" }} />,
    text: "API",
    value: "api",
    path: routes.apiKey.to(),
  },
  {
    icon: robotIcon,
    activeIcon: (
      <DollarCircleOutlined style={{ color: mainColor, fontSize: "25px" }} />
    ),
    text: (
      <React.Fragment>
        {/* {t(sourceKey.home, "aiRobot")} */}
        <br />
        机器人策略
      </React.Fragment>
    ),
    value: "robot",
    // path: routes.robot.to(),
    path: routes.highFrequency.to({ type: 3 }),
  },
  // {
  //     icon: ustdIcon,
  //     activeIcon: <DollarCircleOutlined style={{ color: mainColor, fontSize: '25px' }} />,
  //     text: <React.Fragment>
  //         USDT Supplier<br/>USDT兑换商
  //     </React.Fragment>,
  //     value: 'robot',
  //     path: routes.robot.to(),
  // },
  // {
  //     icon: spotIcon,
  //     activeIcon: <FileSearchOutlined style={{ color: mainColor, fontSize: '25px' }} />,
  //     text: <React.Fragment>
  //         SPOT TRADE<br/>自定现货交易
  //     </React.Fragment>,
  //     value: 'spot',
  //     path: routes.spot.to(),
  // },
];

export const navItems1 = [
  {
    icon: apiNewIconImg,
    activeIcon: <HomeOutlined style={{ color: mainColor, fontSize: "25px" }} />,
    text: <React.Fragment>Api</React.Fragment>,
    value: "api",
    path: routes.apiKeyListing.to(),
  },
  {
    icon: performanceNavImg,
    activeIcon: <HomeOutlined style={{ color: mainColor, fontSize: "25px" }} />,
    text: "performance",
    value: "performance",
    path: routes.explore.to(),
  },
  {
    icon: robotNavImg,
    activeIcon: <HomeOutlined style={{ color: mainColor, fontSize: "25px" }} />,
    text: "robot",
    value: "robot",
    path: routes.robot.to(),
  },
  {
    icon: walletIconImg,
    activeIcon: <HomeOutlined style={{ color: mainColor, fontSize: "25px" }} />,
    text: "wallet",
    value: "wallet",
    path: routes.transaction.to(),
  },
  {
    icon: assetNewIconImg,
    activeIcon: <HomeOutlined style={{ color: mainColor, fontSize: "25px" }} />,
    text: "asset",
    value: "asset",
    path: routes.asset.to(),
  },
  {
    icon: infoNewIconImg,
    activeIcon: (
      <DollarCircleOutlined style={{ color: mainColor, fontSize: "25px" }} />
    ),
    text: "info",
    value: "info",
    path: routes.newsAndVideos.to(),
  },
  {
    icon: faqNewIconImg,
    activeIcon: (
      <DollarCircleOutlined style={{ color: mainColor, fontSize: "25px" }} />
    ),
    text: "faq",
    value: "faq",
    path: routes.faq.to(),
  },
  {
    icon: downloadNewIconImg,
    activeIcon: (
      <DollarCircleOutlined style={{ color: mainColor, fontSize: "25px" }} />
    ),
    text: "download",
    value: "download",
    // path: routes.rewardExplore.to(),
    // path: "",
    popUpClick: "download",
  },
  {
    icon: referenceIcon,
    activeIcon: (
      <DollarCircleOutlined style={{ color: mainColor, fontSize: "25px" }} />
    ),
    text: "reference",
    value: "demoInternal",
    // path: routes.rewardExplore.to(),
    path: demoInternalURl,
  },
];

export const pairingItems1 = [
  {
    icon: exploreCommunityImg,
    activeIcon: <HomeOutlined style={{ color: mainColor, fontSize: "25px" }} />,
    text: <React.Fragment>Api</React.Fragment>,
    value: "community",
    path: routes.exploreCommunity.to(),
  },
  {
    icon: register2Img,
    activeIcon: <HomeOutlined style={{ color: mainColor, fontSize: "25px" }} />,
    text: "register",
    // (
    //   <React.Fragment>
    //     {useTranslation().t(sourceKey.home, "asset")}
    //     <br></br>资产
    //   </React.Fragment>
    // )
    value: "register",
    path: routes.register.to(),
  },
  {
    icon: package2Img,
    activeIcon: (
      <DollarCircleOutlined style={{ color: mainColor, fontSize: "25px" }} />
    ),
    text: "packageUpgrade",
    value: "packageUpgrade",
    path: routes.topUp.to(),
  },
  {
    icon: incomeCap2Img,
    activeIcon: (
      <DollarCircleOutlined style={{ color: mainColor, fontSize: "25px" }} />
    ),
    text: "incomeCapacity",
    value: "incomeCapacity",
    path: routes.incomeCapacitySummary.to(),
  },
];

export const rewardItems1 = [
  {
    icon: directReward2Img,
    activeIcon: <HomeOutlined style={{ color: mainColor, fontSize: "25px" }} />,
    text: <React.Fragment>Api</React.Fragment>,
    value: "directReward",
    path: routes.directReward.to(),
  },
  {
    icon: placementReward2Img,
    activeIcon: <HomeOutlined style={{ color: mainColor, fontSize: "25px" }} />,
    text: "placementReward",
    // (
    //   <React.Fragment>
    //     {useTranslation().t(sourceKey.home, "asset")}
    //     <br></br>资产
    //   </React.Fragment>
    // )
    value: "placementReward",
    path: routes.placementReward.to(),
  },
  {
    icon: leaderReward2Img,
    activeIcon: (
      <DollarCircleOutlined style={{ color: mainColor, fontSize: "25px" }} />
    ),
    text: "leaderReward",
    value: "leaderReward",
    path: routes.leaderReward.to(),
  },
  // {
  //   icon: vipRewardImg,
  //   activeIcon: (
  //     <DollarCircleOutlined style={{ color: mainColor, fontSize: "25px" }} />
  //   ),
  //   text: "vipReward",
  //   value: "vipReward",
  //   path: routes.vipReward.to(),
  // },
];

export const allItems1 = [
  ...navItems1,
  ...pairingItems1,
  ...rewardItems1,
  {
    icon: simulatorNewIconImg,
    activeIcon: <HomeOutlined style={{ color: mainColor, fontSize: "25px" }} />,
    text: "demo",
    value: "demo",
    // path: "https://demo.indoits.com/login",
    path: demoURl,
  },
  {
    icon: nodeTeam2Img,
    activeIcon: <HomeOutlined style={{ color: mainColor, fontSize: "25px" }} />,
    text: "nodeTeam",
    value: "nodeTeam",
    path: routes.nodeTeam.to(),
  },
  {
    icon: revenueNewIconImg,
    activeIcon: (
      <DollarCircleOutlined style={{ color: mainColor, fontSize: "25px" }} />
    ),
    text: "revenue",
    value: "revenue",
    path: routes.incomeCapHome.to(),
  },
];

export const defaultItems = [
  {
    icon: simulatorNewIconImg,
    activeIcon: <HomeOutlined style={{ color: mainColor, fontSize: "25px" }} />,
    text: "demo",
    value: "demo",
    // path: "https://demo.indoits.com/login",
    path: demoURl,
  },
  {
    icon: revenueNewIconImg,
    activeIcon: (
      <DollarCircleOutlined style={{ color: mainColor, fontSize: "25px" }} />
    ),
    text: "revenue",
    value: "revenue",
    path: routes.incomeCapHome.to(),
  },
  {
    icon: performanceNavImg,
    activeIcon: <HomeOutlined style={{ color: mainColor, fontSize: "25px" }} />,
    text: "performance",
    value: "performance",
    path: routes.explore.to(),
  },
  {
    icon: robotNavImg,
    activeIcon: <HomeOutlined style={{ color: mainColor, fontSize: "25px" }} />,
    text: "robot",
    value: "robot",
    path: routes.robot.to(),
  },
  {
    icon: walletIconImg,
    activeIcon: <HomeOutlined style={{ color: mainColor, fontSize: "25px" }} />,
    text: "wallet",
    value: "wallet",
    path: routes.transaction.to(),
  },
  {
    icon: assetNewIconImg,
    activeIcon: <HomeOutlined style={{ color: mainColor, fontSize: "25px" }} />,
    text: "asset",
    value: "asset",
    path: routes.asset.to(),
  },
  {
    icon: downloadNewIconImg,
    activeIcon: (
      <DollarCircleOutlined style={{ color: mainColor, fontSize: "25px" }} />
    ),
    text: "download",
    value: "download",
    popUpClick: "download",
  },
  {
    icon: faqNewIconImg,
    activeIcon: (
      <DollarCircleOutlined style={{ color: mainColor, fontSize: "25px" }} />
    ),
    text: "faq",
    value: "faq",
    path: routes.faq.to(),
  },
  {
    icon: infoNewIconImg,
    activeIcon: (
      <DollarCircleOutlined style={{ color: mainColor, fontSize: "25px" }} />
    ),
    text: "info",
    value: "info",
    path: routes.newsAndVideos.to(),
  },
];
