import queryString from "query-string";

export const routes = {
  home: {
    // to: () => "/",
    to: (query = {}) => {
      let queryStringified = queryString.stringify(query);
      return `/?${queryStringified}`;
    },
  },
  login: {
    to: () => "/login",
  },
  register: {
    to: (query = {}) => {
      let queryStringified = queryString.stringify(query);
      return `/register?${queryStringified}`;
    },
  },
  setting: {
    to: (query = {}) => {
      let queryStringified = queryString.stringify(query);
      return `/setting?${queryStringified}`;
    },
  },
  // setting: {
  //   to: () => "/setting",
  // },
  billing: {
    to: () => "/billing",
  },
  explore: {
    to: () => "/explore",
  },
  profile: {
    to: () => "/profile",
  },
  apiKey: {
    to: (query = {}) => {
      let queryStringified = queryString.stringify(query);
      return `/api_key?${queryStringified}`;
    },
  },
  apiKeyPlatform: {
    to: () => "/api_platform",
  },
  apiKeyListing: {
    to: () => "/api_listing",
  },
  robot: {
    to: () => "/robot",
  },
  spot: {
    to: () => "/spot",
  },
  usdt: {
    to: () => "/usdt",
  },
  info: {
    to: () => "/info",
  },
  binance: {
    to: () => "/binance",
  },
  huobi: {
    to: () => "/huobi",
  },
  other: {
    to: () => "/other",
  },
  futures: {
    to: () => "/futures",
  },
  highFrequency: {
    to: (query = {}) => {
      let queryStringified = queryString.stringify(query);
      return `/high_frequency?${queryStringified}`;
    },
  },
  robotSimulate: {
    to: () => "/robotSimulate",
  },
  robotSimulateListing: {
    to: () => "/robotSimulateListing",
  },
  robotSimulateDetails: {
    to: (id) => `/robotSimulateDetails`,
  },
  highFrequencySimulator: {
    to: (query = {}) => {
      let queryStringified = queryString.stringify(query);
      return `/high_frequency_simulate?${queryStringified}`;
    },
  },
  safetyPin: {
    to: () => "/safety_pin",
  },
  chase: {
    to: () => "/chase",
  },
  futureDetails: {
    to: (id) => `/product/future/detail`,
  },
  highFrequencyDetails: {
    to: (id) => `/product/high_frequency/detail`,
  },
  safetyPinDetails: {
    to: (id) => `/product/safety_pin/detail`,
  },
  chaseDetails: {
    to: (id) => `/product/chase/detail`,
  },
  record: {
    to: () => "/record",
  },
  inviteFriend: {
    to: () => "/invite_friend",
  },
  community: {
    to: () => "/community",
  },
  tradeRecord: {
    to: () => "/trade_record",
  },
  transaction: {
    to: () => "/transaction",
  },
  customerService: {
    to: () => "/customer_service",
  },
  asset: {
    to: () => "/asset",
  },
  deposit: {
    to: () => "/deposit",
  },
  interTransfer: {
    to: () => "/inter_transfer",
  },
  withdrawal: {
    to: () => "/withdrawal",
  },
  activationCode: {
    to: () => "/activation_code",
  },
  forgotPassword: {
    to: () => "/forgot_password",
  },
  emailVerification: {
    to: (query = {}) => {
      let queryStringified = queryString.stringify(query);
      return `/email_verification?${queryStringified}`;
    },
  },
  resetPassword: {
    to: (query = {}) => {
      let queryStringified = queryString.stringify(query);
      return `/reset_password?${queryStringified}`;
    },
  },
  address: {
    to: () => "/address",
  },
  comingSoon: {
    to: () => "/coming_soon",
  },
  under_maintenance: {
    to: () => "/under_maintenance",
  },
  depositList: {
    to: () => "/deposit_list",
  },
  depositDetails: {
    to: () => "/deposit_details",
  },
  withdrawalList: {
    to: () => "/withdrawal_list",
  },
  withdrawalDetails: {
    to: () => "/withdrawal_details",
  },
  transferList: {
    to: () => "/inter_transfer_list",
  },
  liquidizedRobotList: {
    to: () => "/liquidized_robot_list",
  },
  communityRewards: {
    to: () => "/community_rewards",
  },
  communityInfo: {
    to: () => "/community_info",
  },
  news: {
    to: () => "/news",
  },
  videos: {
    to: () => "/videos",
  },
  newsAndVideos: {
    to: () => "/newsAndVideo",
  },
  faq: {
    to: () => "/faq",
  },
  rewardExplore: {
    // to: (query = {}) => {
    //   let queryStringified = queryString.stringify(query);
    //   return `/rewardExplore?${queryStringified}`;
    // },
    to: () => "/rewardExplore",
  },
  directReward: {
    to: () => "/rewardExplore/rewards/directReward",
  },
  placementReward: {
    to: () => "/rewardExplore/rewards/placementReward",
  },
  leaderReward: {
    to: () => "/rewardExplore/rewards/leaderReward",
  },
  vipReward: {
    to: () => "/rewardExplore/rewards/vipReward",
  },
  topUp: {
    to: () => "/rewardExplore/topUp",
  },
  exploreCommunity: {
    to: () => "/rewardExplore/community",
  },
  incomeCapacity: {
    to: () => "/rewardExplore/incomeCapacity",
  },
  nodeTeam: {
    to: (query = {}) => {
      let queryStringified = queryString.stringify(query);
      return `/rewardExplore/nodeTeam?${queryStringified}`;
    },
  },
  incomeCapacitySummary: {
    to: () => "/incomeCap_summary",
  },
  incomeCapHome: {
    to: () => "/incomeCap_home",
  },
  staticCap: {
    to: () => "/static_capacity",
  },
  dynamicCap: {
    to: () => "/dynamic_capacity",
  },
  vipCap: {
    to: () => "/vip_capacity",
  },
  campaign: {
    to: (query = {}) => {
      let queryStringified = queryString.stringify(query);
      return `/campaign?${queryStringified}`;
    },
  },
  documents: {
    to: () => "/documents",
  },
};
