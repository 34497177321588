import mainJson from "./Main.json";
import loginJson from "./Login.json";
import commonJson from "./Common.json";
import homePageJson from "./HomePage.json";
import apiJson from "./Api.json";
import assetJson from "./Asset.json";
import highFrequencyJson from "./HighFrequency.json";
import newAndVideo from "./NewsAndVideo.json";
import pairingJson from "./Pairing.json";
import pairingErrorJson from "./PairingError.json";
import profileJson from "./Profile.json";
import faqJson from "./Faq.json";

import { forEach, mapKeys } from "lodash";
import { sourceKey } from "../config";

const objectsToRemap = [
  {
    value: mainJson,
    key: "",
  },
  {
    value: loginJson,
    key: "",
  },
  {
    value: commonJson,
    key: "",
  },
  {
    value: homePageJson,
    key: sourceKey.home,
  },
  {
    value: apiJson,
    key: sourceKey.api,
  },
  {
    value: assetJson,
    key: sourceKey.asset,
  },
  {
    value: profileJson,
    key: sourceKey.profile,
  },
  {
    value: highFrequencyJson,
    key: sourceKey.highFrequency,
  },
  {
    value: pairingJson,
    key: sourceKey.pairing,
  },
  {
    value: pairingErrorJson,
    key: sourceKey.pairing,
  },
  {
    value: newAndVideo,
    key: sourceKey.newAndVideo,
  },
  {
    value: faqJson,
    key: sourceKey.faq,
  },
];

let remappedObjects = {};
forEach(objectsToRemap, (item) => {
  let newObj = item.value;
  if (item.key) {
    newObj = mapKeys(item.value, (value, key) => {
      return `${item.key}.${key}`;
    });
  }

  remappedObjects = {
    ...remappedObjects,
    ...newObj,
  };
});

const allCnJson = {
  ...remappedObjects,
};

// console.log("cn", allCnJson);

export default allCnJson;
