export const profileTransactionsType = {
  deposit: 1,
  activationCode: 2,
  withdrawal: 3,
  transfer: 4,
  activationCodeCommission: 5,
  highFrequencyCommission: 6,
  pool: 7,
  withdrawalServiceCharge: 8,
  robotRefundDeposit: 9,
};

export const profileTransactionsAmountType = {
  usdt: 0,
  vsdt: 1,
  voucher: 2,
  itsv: 3
};

export const placementTransactionDesc = [
  "topUp",
  "bonus",
  "bonusPairing",
  "bonusLeader",
  "walletTopUp",
  "withdrawWallet",
  "requestDeductStatic",
  "requestAddStatic",
  "transferIn",
  "transferOut",
  "bonusPool",
  "bonusPool1",
  "binaryReport",
];
