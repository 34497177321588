import { set, toNumber } from "lodash";
import get from "lodash.get";
import isPlainObject from "lodash.isplainobject";
import { tradeSymbols } from "./data";

let socket;
let marketPrices = {};
export function openBinancePriceSocket() {
  try {
    // console.log(socket);
    if (typeof get(socket, "close") === "function") {
      socket.close();
      // console.log('closed');
    }
    socket = new WebSocket("wss://stream.binance.com:9443/ws");
    const msg = {
      method: "SUBSCRIBE",
      params: tradeSymbols,
      id: 1,
    };

    socket.addEventListener("open", () => {
      // console.log('opened');

      socket.send(JSON.stringify(msg));
    });

    socket.addEventListener("message", async (e) => {
      var value = JSON.parse(e.data);
      const time = new Date().getTime();
      if (value?.e === "trade") {
        try {
          let marketSymbol = value?.s;
          let marketPrice = parseFloat(value?.p);
          if (get(marketPrices, `${marketSymbol}.price`)) {
            set(marketPrices, `${marketSymbol}.lastGetTime`, time);
            set(
              marketPrices,
              `${marketSymbol}.lastPrice`,
              get(marketPrices, `${marketSymbol}.price`)
            );
            set(
              marketPrices,
              `${marketSymbol}.priceDiff`,
              toNumber(marketPrice) -
                toNumber(get(marketPrices, `${marketSymbol}.price`))
            );
            set(
              marketPrices,
              `${marketSymbol}.priceDiffPercentage`,
              get(marketPrices, `${marketSymbol}.priceDiff`) /
                toNumber(get(marketPrices, `${marketSymbol}.price`))
            );
          }
          set(marketPrices, `${marketSymbol}.price`, marketPrice);
        } catch (error) {
          console.log("1", { error });
        }
      }
    });
  } catch (error) {
    console.log(error);
  }
}

// export function setBinanceMarketPrices(symbol, price, lastGetTime) {
//   if (!isPlainObject(marketPrices)) {
//     marketPrices = {}
//   }
//   if (get(marketPrices, `${symbol}.price`)) {
//     set(marketPrices, `${symbol}.lastGetTime`, lastGetTime)
//     set(marketPrices, `${symbol}.lastPrice`, get(marketPrices, `${symbol}.price`))
//     set(marketPrices, `${symbol}.priceDiff`, toNumber(price) - toNumber(get(marketPrices, `${symbol}.price`)))
//     set(marketPrices, `${symbol}.priceDiffPercentage`, get(marketPrices, `${symbol}.priceDiff`) / toNumber(get(marketPrices, `${symbol}.price`)))
//   }
//   //console.log(marketPrices);

// }

export function getBinanceMarketPrices(symbol) {
  if (!isPlainObject(marketPrices)) {
    marketPrices = {};
  }
  return symbol ? marketPrices[symbol] : marketPrices;
}
