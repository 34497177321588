import { Form, Input, message, Modal, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { forEach, get } from "lodash";
import React, { useEffect, useState } from "react";
import MediaQuery from "react-responsive";

// import getPlacements from "../../../newApi/rewardExplore/getPlacements";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { feathers } from "../../../api/feathers_rest";
import { useTranslation } from "../../../locales/useTranslation";
import patchSecondPassword from "../../../newApi/user/patchSecondPassword";
import sendRegisterVerificationEmail from "../../../newApi/user/sendRegisterVerificationEmail";
import validateSecondPassword from "../../../newApi/user/validateSecondPassword";
import getProfiles from "../../../newApi/profile/getProfiles";

//let displayRobot = [];

// markup
// let timeoutFunc;
const PairingLoginModal = (props) => {
  // const location = useLocation();
  const { t } = useTranslation();
  const [form] = useForm();
  const [form2] = useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [forgotVisible, setForgotVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [emailCode, setEmailCode] = useState("");
  const [loading2, setLoading2] = useState(false);
  const [profile, setProfile] = useState({});

  //let profile = get(props.user.user, "profile");
  let mainUser = get(props, "user");

  // console.log(props);

  useEffect(() => {
    setVisible(props.visible === true);
    if (props.visible === true) {
      getProfileAPI();
    }
  }, [props.visible]);

  function getProfileAPI() {
    if (get(mainUser, "profile")) {
      getProfiles(1, 0, {
        _id: get(mainUser, "profile._id"),
      })
        .then((res) => {
          setProfile(get(res, "data[0]"));
          // console.log(get(res, "data[0]"));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  async function sendEmailVerificationCode() {
    setLoading2(true);

    let mail = {
      to: get(profile, "email"),
      subject: "IndoITS Reset Security Password Verification Code",
      text: "You have requested to reset your security password. ",
      text2: "Here is your verification code: ",
    };
    sendRegisterVerificationEmail(mail)
      .then((res) => {
        if (get(res, "status") === 200) {
          const data = get(res, "data");

          let emailObject = {
            to: get(data, "email"),
            code: get(data, "emailCode"),
            type: 2,
          };

          feathers("emails")
            .create(emailObject)
            .then((res) => {
              setEmailCode((prevState) => {
                return get(data, "emailCode");
              });

              setEmail((prevState) => {
                return get(data, "email");
              });

              setTimeout(() => {
                setEmailCode((prevState) => {
                  return "";
                });
              }, get(data, "ttl"));

              message.success(t("sendEmailCodeDesc"), 6);
              setLoading2(false);
            })
            .catch((err) => {
              message.error(t("failAddEmailRecord"));
              setLoading2(false);
            });
        } else {
          message.error(t("failSendEmail"));
          setLoading2(false);
        }
      })
      .catch((err) => {
        message.error(err.message);
        setLoading2(false);
      });
  }

  const submitPassword = () => {
    form
      .validateFields()
      .then((values) => {
        // console.log(values);
        let { secondPassword } = values;
        validateSecondPassword({
          secondPassword,
          username: get(mainUser, "username"),
        })
          .then((res) => {
            // console.log(res);
            message.success(t("success"));
            form.resetFields();
            if (props.onSuccess) {
              props.onSuccess();
            }
            setVisible(false);
          })
          .catch((err) => {
            console.log(err);
            // message.error(err?.message);
            message.error(t("invalidPassword"));
          });
      })
      .catch((err) => {
        forEach(get(err, "errorFields"), (item) => {
          message.error(get(item, "errors[0]"));
          setLoading(false);
        });
      });
  };

  function forgotPasswordClick() {
    sendEmailVerificationCode();
    setForgotVisible(true);
  }

  function changePassword() {
    form2
      .validateFields()
      .then((values) => {
        // console.log(values);
        if (values.checkCode === emailCode) {
          patchSecondPassword({
            username: get(mainUser, "username"),
            secondPassword: get(values, "secondPassword"),
          })
            .then((res) => {
              //   console.log(res);
              setForgotVisible(false);
              message.success(t("success"));
              form2.resetFields();
            })
            .catch((err) => {
              console.log(err);
              message.error(err?.message);
            });
        } else {
          message.error(t("incorrectEmailCode"));
          //   form.resetFields();
        }
      })
      .catch((err) => {
        forEach(get(err, "errorFields"), (item) => {
          message.error(get(item, "errors[0]"));
          setLoading(false);
        });
      });
  }

  const _renderModalContent = () => {
    return (
      <div
        className="lightgreybox-bg-color  pb-10 pt-5 rounded-3xl"
        // style={{ borderRadius: 15 }}
      >
        <Form form={form} autoComplete="off" style={{ color: "white" }}>
          <div className="rounded-3xl m-6 ">
            <div className="pb-2 font-semibold">{t("password")}</div>
            <Form.Item
              name="secondPassword"
              //   className="my-select-container"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: (
                    <React.Fragment>
                      {/* 请输入密码 <br /> */}
                      {t("insertPassword")}
                    </React.Fragment>
                  ),
                },
              ]}
            >
              <Input.Password
                placeholder=""
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                className={"input-border"}
              />
            </Form.Item>
          </div>
        </Form>
        <div className=" flex justify-center">
          <span>
            {t("forgotSecurity")}
            {"? "}
            <a
              onClick={() => {
                forgotPasswordClick();
              }}
              style={{ color: "#fcd535" }}
            >
              {t("clickHere")}
            </a>
          </span>
        </div>
        <div className="flex justify-around mt-10">
          <span
            className="second-button-color border  py-2 text-center  rounded-3xl cursor-pointer mx-4"
            style={{ width: "100%" }}
            onClick={() => {
              // navigate(routes.home.to());
              props.onClose();
              setVisible(false);
            }}
          >
            {t("close")}
          </span>
          <span
            className="rounded-3xl main-button-color text-center  py-2 cursor-pointer mx-4"
            onClick={() => {
              submitPassword();
            }}
            style={{ width: "100%" }}
          >
            {t("enter")}
          </span>
        </div>
      </div>
    );
  };

  const _renderForgotModal = () => {
    const _renderForgotContent = () => {
      return (
        <div
          className="lightgreybox-bg-color  pb-10 pt-5 rounded-3xl"

          // style={{ borderRadius: 15 }}
        >
          <Spin spinning={loading2}>
            <Form form={form2} autoComplete="off" style={{ color: "white" }}>
              <div className="rounded-3xl m-6 ">
                <div className="pb-2 font-semibold">{t("emailCode")}</div>
                <Form.Item
                  name="checkCode"
                  //   className="my-select-container"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: (
                        <React.Fragment>
                          {/* 请输入验证码 <br /> */}
                          {t("emailCodeRequired")}
                        </React.Fragment>
                      ),
                    },
                  ]}
                >
                  <Input
                    placeholder=""
                    autoComplete="off"
                    className={"input-border"}
                  />
                </Form.Item>
              </div>
              <div className="rounded-3xl m-6 ">
                <div className="pb-2 font-semibold"> {t("password")}</div>
                <Form.Item
                  name="secondPassword"
                  //   className="my-select-container"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: (
                        <React.Fragment>
                          {/* 请输入密码 <br /> */}
                          {t("passwordRequired")}
                        </React.Fragment>
                      ),
                    },
                  ]}
                >
                  <Input.Password
                    placeholder=""
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    className={"input-border"}
                  />
                </Form.Item>
              </div>
              <div className="rounded-3xl m-6 ">
                <div className="pb-2 font-semibold">{t("confirmPassword")}</div>
                <Form.Item
                  name="loginPass"
                  required
                  style={{ margin: 0 }}
                  initialValue=""
                  rules={[
                    {
                      required: true,
                      message: (
                        <React.Fragment>
                          {/* 请确认密码
                          <br /> */}
                          {t("confirmPasswordRequired")}
                        </React.Fragment>
                      ),
                    },
                    {
                      validator: (rule, value, callback) => {
                        if (
                          value !== form2.getFieldValue("secondPassword") &&
                          value
                        ) {
                          callback(t("passwordNotMatch"));
                        } else {
                          callback();
                        }
                      },
                    },
                  ]}
                >
                  <Input.Password
                    placeholder=""
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    autoComplete="off"
                    className={"input-border"}
                  />
                </Form.Item>
              </div>
            </Form>

            <div className="flex justify-around mt-10">
              <span
                className="second-button-color border  py-2 text-center  rounded-3xl cursor-pointer mx-4"
                style={{ width: "100%" }}
                onClick={() => {
                  setForgotVisible(false);
                  form2.resetFields();
                  setEmailCode("");
                  setEmail("");
                }}
              >
                {t("close")}
              </span>
              <span
                className="rounded-3xl main-button-color text-center  py-2 cursor-pointer mx-4"
                onClick={() => {
                  changePassword();
                }}
                style={{ width: "100%" }}
              >
                {t("enter")}
              </span>
            </div>
          </Spin>
        </div>
      );
    };

    return (
      <>
        <MediaQuery maxWidth={590}>
          <Modal
            className="no-padding-modal-body modal-body-background-transparent"
            centered
            maskClosable
            width="70%"
            mask
            footer={null}
            closable={false}
            visible={forgotVisible}
            style={{ borderRadius: "10px" }}
          >
            {_renderForgotContent()}
          </Modal>
        </MediaQuery>
        <MediaQuery minWidth={591}>
          <Modal
            className="no-padding-modal-body modal-body-background-transparent"
            centered
            maskClosable
            width="40%"
            mask
            footer={null}
            closable={false}
            visible={forgotVisible}
            style={{ borderRadius: "10px" }}
          >
            {_renderForgotContent()}
          </Modal>
        </MediaQuery>
      </>
    );
  };

  return (
    <React.Fragment>
      <div className="p-4">
        <MediaQuery maxWidth={590}>
          <Modal
            className="no-padding-modal-body modal-body-background-transparent"
            centered
            maskClosable
            width="70%"
            mask
            footer={null}
            closable={false}
            visible={visible}
            style={{ borderRadius: "10px" }}
          >
            {_renderModalContent()}
            {_renderForgotModal()}
          </Modal>
        </MediaQuery>
        <MediaQuery minWidth={591}>
          <Modal
            className="no-padding-modal-body modal-body-background-transparent"
            centered
            maskClosable
            width="40%"
            mask
            footer={null}
            closable={false}
            visible={visible}
            style={{ borderRadius: "10px" }}
          >
            {_renderModalContent()}
            {_renderForgotModal()}
          </Modal>
        </MediaQuery>
      </div>
    </React.Fragment>
  );
};

export default PairingLoginModal;
