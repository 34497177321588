import {
  CopyOutlined,
  DownloadOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { useLocation } from "@reach/router";
import { message, Modal, Spin } from "antd";
import copy from "copy-to-clipboard";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { androidImg } from "../../../images";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { apkLink } from "../../../utilities/startUp";

let timeoutFunc;

// markup
const MessageModal = (props) => {
  const title = "Please Download: ";
  const location = useLocation();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [shareVisible, setShareVisible] = useState(false);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    setVisible(props.visible === true);
  }, [props.visible]);

  function close() {
    if (props.onClose) {
      props.onClose();
    } else {
      setVisible(false);
    }
  }

  function handleOk() {
    setLoading(true);
    if (props.onOk) {
      clearTimeout(timeoutFunc);
      setTimeout(() => {
        props.onOk();
        setLoading(false);
      }, 200);
    }
  }

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <Modal
          wrapClassName="no-padding-modal-body modal-body-background-transparent"
          centered
          maskClosable
          mask
          footer={null}
          className=""
          visible={visible}
          onCancel={() => {
            close();
          }}
        >
          <div className="lightgreybox-bg-color rounded-3xl px-5 py-10">
            <div className="box-bg-color flex justify-between p-4 rounded-3xl">
              <div style={{ cursor: "" }}>
                <img src={androidImg} width={42} height={51} />
              </div>
              <div className="flex justify-end">
                <div className=" flex justify-center mr-4  ">
                  <DownloadOutlined
                    className="bg-main-color-gradient p-2 rounded-full"
                    onClick={(e) => {
                      window.location.href = apkLink;
                    }}
                    style={{
                      color: "white",
                      fontSize: "34px",
                      cursor: "pointer",
                    }}
                  />
                </div>
                <div className=" flex justify-center mr-2 ">
                  <ShareAltOutlined
                    className="rounded-full p-2"
                    onClick={() => {
                      setShareVisible(true);
                    }}
                    style={{
                      color: "white",
                      backgroundColor: "#575757",
                      border: "thin",
                      borderColor: "white",
                      borderStyle: "solid",
                      fontSize: "34px",
                      cursor: "pointer",
                    }}
                  />
                </div>
                <div className=" flex justify-center mr-2 ">
                  <CopyOutlined
                    className="rounded-full p-2"
                    onClick={() => {
                      message.success(t("copied", sourceKey.asset));
                      copy(apkLink);
                    }}
                    style={{
                      color: "white",
                      backgroundColor: "#575757",
                      border: "thin",
                      borderColor: "white",
                      borderStyle: "solid",
                      fontSize: "34px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className="bg-main-color-gradient p-2 mt-2 rounded-3xl cursor-pointer"
              onClick={() => {
                close();
              }}
            >
              <div className="flex justify-center"> {t("close")} </div>
            </div>
          </div>

          <Modal
            wrapClassName="no-padding-modal-body modal-body-background-transparent"
            centered
            maskClosable
            mask
            footer={null}
            className=""
            visible={shareVisible}
            onCancel={() => {
              //  close();
              setShareVisible(false);
            }}
          >
            <div className="lightgreybox-bg-color rounded-3xl px-5 py-10">
              <div className="flex justify-around p-4 rounded-3xl">
                {/* <FacebookShareButton url={apkLink}>
                  <FacebookIcon size={40} round={true} />
                </FacebookShareButton> */}

                <TelegramShareButton url={apkLink}>
                  <TelegramIcon size={40} round={true} />
                </TelegramShareButton>

                <WhatsappShareButton url={apkLink}>
                  <WhatsappIcon size={40} round={true} />
                </WhatsappShareButton>
              </div>
              <div
                className="bg-main-color-gradient p-2 mt-2 rounded-3xl cursor-pointer"
                onClick={() => {
                  setShareVisible(false);
                }}
              >
                <div className="flex justify-center"> {t("close")} </div>
              </div>
            </div>
          </Modal>
        </Modal>
      </Spin>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
};
export default connect(mapStateToProps, mapDispatchToProps)(MessageModal);
