import axios from "axios";
import localStorage from "local-storage";
import {
  camelCase,
  concat,
  forEach,
  get,
  isEmpty,
  isNumber,
  isPlainObject,
  isString,
  snakeCase,
} from "lodash";
import client from "../../feathers";
import { decrypt, encrypt } from "../utilities/session";
import { sessionPass } from "../utilities/startUp";

export const routePrefix = {
  profile: "profile",
  asset: "asset",
  robot: "robots",
  parmRankings: "parm-rankings",
  enquiry: "enquiry",
  systemConfiguration: "system-configuration",
  user: "users",
  email: "email",
};

let accessKey2 = localStorage.get("accessKey");

// export const apiUrl = `${client.io.io.uri}api`;
export const apiUrl = `${client.io.io.uri}app`;

const PAGESIZE = 30;

export function getSessionType(method, url) {
  const newUrl = url.replace(`${client.io.io.uri}`, "/");
  //--------------------------------------------------------------//
  let timestamp = new Date().getTime();
  let authId = new Date().getTime();
  let encryptionKey = sessionPass;
  const sessionDesc = localStorage.get("sessionInfo") || {};

  if (!isEmpty(sessionDesc)) {
    let decryptKey = decrypt(encryptionKey, sessionDesc?.sessionId || "", sessionDesc?.sessionUse || "");

    let value = `${method}=${newUrl}-${authId}${timestamp}`;

    let initVar = `${authId}${timestamp}`;

    let authCode = encrypt(decryptKey, initVar, value);

    return ({
      authCode, timestamp, authId
    });
  }

  return {}
}

export const defaultPopulatedFieldName = (field) =>
  camelCase(`populated_${snakeCase(field)}`);

export async function _base_axios_get(url, query, accessKey = "", header = {}) {
  const session = getSessionType("GET", url);
  if (!url) {
    return new Promise((resolve, reject) => {
      reject({
        message: "Url Not Found!",
      });
    });
  }

  if (!isPlainObject(query)) {
    query = {};
  }

  return await axios
    .get(`${url}`, {
      params: {
        ...query,
      },
      headers: {
        Authorization: "Bearer " + accessKey2,
        "auth-code": session?.authCode,
        "auth-id": session?.authId,
        timestamp: session?.timestamp,
        ...header
      },
    })
    .then((res) => {
      return get(res, "data");
    })
    .catch((err) => {
      return new Promise((resolve, reject) => {
        reject({
          error: err,
          message: get(err, "response.data.message") || "Something went wrong!",
          errorType: get(err, "response.data.errorType") || "apiError",
        });
      });
    });
}

export async function _base_axios_post(url, query, accessKey = "", header = {}) {
  const session = getSessionType("POST", url);
  if (!url) {
    return new Promise((resolve, reject) => {
      reject({
        message: "Url Not Found!",
      });
    });
  }

  if (!isPlainObject(query)) {
    query = {};
  }

  return await axios
    .post(
      `${url}`,
      {
        ...query,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessKey2,
          "auth-code": session?.authCode,
          "auth-id": session?.authId,
          timestamp: session?.timestamp,
          ...header
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return new Promise((resolve, reject) => {
        reject({
          error: err,
          message: get(err, "response.data.message") || "Something went wrong!",
          errorType: get(err, "response.data.errorType") || "apiError",
          errorCode: get(err, "response.data.code") || "",
        });
      });
    });
}

export async function _axios_base_get_list(
  url,
  limit = PAGESIZE,
  skip = 0,
  query = {},
  accessKey = "",
  header = {}
) {
  const session = getSessionType("GET", url);
  if (!isString(url)) {
    return new Promise((resolve, reject) => {
      reject({
        type: "url_not_found",
        message: "Url not found",
      });
    });
  }

  let getAll = limit === "all";
  if (!isNumber(limit)) {
    limit = PAGESIZE;
  }

  if (getAll) {
    limit = 50;
  }

  if (!isNumber(skip)) {
    skip = 0;
  }

  if (!isPlainObject(query)) {
    query = {};
  }

  return await axios
    .get(url, {
      params: {
        ...query,
        limit: limit,
        skip: skip,
      },
      headers: {
        Authorization: "Bearer " + accessKey2,
        "auth-code": session?.authCode,
        "auth-id": session?.authId,
        timestamp: session?.timestamp,
        ...header
      },
    })
    .then(async (res) => {
      if (getAll) {
        let leftPage = Math.ceil(
          ((get(res, `data.total`) || 0) - limit) / limit
        );
        if (leftPage > 0) {
          let promises = [];
          for (let index = 1; index <= leftPage; index++) {
            promises.push(
              _axios_base_get_list(url, limit, index * limit, query, accessKey2)
            );
          }

          let responses = await Promise.all(promises);
          forEach(responses, (response) => {
            res.data.data = concat(
              res.data.data,
              get(response, `data.data`) || []
            );
          });
        }
      }
      return res.data;
    })
    .catch((err) => {
      return new Promise((resolve, reject) => {
        reject({
          errorType: get(err, "response.data.errorType") || "api_error",
          message: get(err, "response.data.message") || "API Error",
          err: err,
        });
      });
    });
}
