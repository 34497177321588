import { Button, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import { loginSuccessful } from "../../../redux/actions/user-actions";

let timeoutFunc;

// markup
const MessageModal = (props) => {
  // const location = useLocation();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  // const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    setVisible(props.visible === true);
  }, [props.visible]);

  function close() {
    if (props.onClose) {
      props.onClose();
    } else {
      setVisible(false);
    }
  }

  function handleOk() {
    setLoading(true);
    if (props.onOk) {
      clearTimeout(timeoutFunc);
      setTimeout(() => {
        props.onOk();
        setLoading(false);
      }, 200);
    }
  }

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <Modal
          wrapClassName="no-padding-modal-body modal-body-background-transparent"
          centered
          maskClosable
          mask
          footer={null}
          className=""
          visible={visible}
          onCancel={() => {
            close();
          }}
        >
          <div className="lightgreybox-bg-color rounded-3xl px-5 py-10">
            {props.children || (
              <div className="flex justify-center items-center font-semibold text-base text-center">
                {/* 您确定吗？
                <br /> */}
                {t("areYouSure", sourceKey.profile)}?
              </div>
            )}

            {props.confirmButton ? (
              props.confirmButton(handleOk)
            ) : (
              <div className="mt-10">
                <Button
                  block
                  className="main-button-color input-border"
                  size="large"
                  loading={loading}
                  onClick={(e) => {
                    handleOk();
                  }}
                >
                  {t("confirm")}
                </Button>
              </div>
            )}
            {props.footer}
          </div>
        </Modal>
      </Spin>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
};
export default connect(mapStateToProps, mapDispatchToProps)(MessageModal);
