import { apiUrl, _axios_base_get_list } from "..";
import { alertPrefix } from "./index";
// const PAGESIZE = 100;

export default function getProfileAlert(
  limit,
  skip = 0,
  query = {},
  accessKey = ""
) {
  return _axios_base_get_list(
    `${apiUrl}/${alertPrefix}/getProfileAlert`,
    limit,
    skip,
    query,
    accessKey
  );
}
