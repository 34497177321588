import React from "react";
import Helmet from "react-helmet";
import { logoIcon, newLogoImg } from "../../../images";

// markup
const SharedHeader = () => {
  return (
    <Helmet>
      {/* <!-- Primary Meta Tags --> */}
      <title>IndoITS Bot - Trading System</title>
      <meta name="title" content="IndoITS- Trading System" />
      <meta name="description" content="The very best platform you needed" />
      <link rel="icon" href={newLogoImg} />
      {/* <!-- Open Graph / Facebook --> */}
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
    </Helmet>
  );
};

export default SharedHeader;
